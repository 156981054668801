@import "@/stylesheets/theme.less";

.filter-container {
	.filter-search-flow-container {
		.ant-input-search {
			.ant-input-wrapper {
				.ant-input-group-addon {
					display: none;
				}
				.ant-input-affix-wrapper {
					border-radius: 4px;
				}
			}
		}
		.scroll-container {
			max-height: 16.2rem;
			overflow: auto;
			&.operator-container {
				margin-top: 1rem;
			}
			.field-list-data-container {
				border: none;
				.ant-col {
					.ant-empty {
						height: 3rem;
						.ant-empty-image {
							svg {
								height: 5rem;
								width: 5rem;
							}
						}
					}
				}
			}
		}
		.filter-tag {
			@apply flex items-center justify-between;
			width: fit-content;
			border: 1px solid @secondaryBorderColor;
			svg {
				margin: 0 0 0 0.5rem;
			}
		}
		.value-input {
			.ant-select-multiple,
			.ant-picker {
				width: 100% !important;
			}
		}
	}
}

@themeRed: #fff1f0, #ffccc7, #ffa39e, #ff7875, #ff4d4f, #f5222d, #cf1322,
	#a8071a, #820014, #5c0011;@themeVolcano: #fff2e8, #ffd8bf, #ffbb96, #ff9c6e, #ff7a45, #fa541c, #d4380d,
	#ad2102, #871400, #610b00;@themeOrange: #fff7e6, #ffe7ba, #ffd591, #ffc069, #ffa940, #fa8c16, #d46b08,
	#ad4e00, #873800, #612500;@themeGold: #fffbe6, #fff1b8, #ffe58f, #ffd666, #ffc53d, #faad14, #d48806,
	#ad6800, #874d00, #613400;@themeYellow: #feffe6, #ffffb8, #fffb8f, #fff566, #ffec3d, #fadb14, #d4b106,
	#ad8b00, #876800, #614700;@themeLime: #fcffe6, #f4ffb8, #eaff8f, #d3f261, #bae637, #a0d911, #7cb305,
	#5b8c00, #3f6600, #254000;@themeGreen: #f6fef9, #f6ffed, #d9f7be, #b7eb8f, #95de64, #73d13d, #52c41a,
	#389e0d, #237804, #135200, #092b00;@themeCyan: #e6fffb, #b5f5ec, #87e8de, #5cdbd3, #36cfc9, #13c2c2, #08979c,
	#006d75, #00474f, #002329;@themeBlue: #e6f7ff, #bae7ff, #91d5ff, #69c0ff, #4b85ff, #2f70ff, #096dd9,
	#0050b3, #003a8c, #002766;@themeGeekBlue: #f6f9ff, #f0f5ff, #d6e4ff, #adc6ff, #85a5ff, #597ef7, #2f54eb,
	#1d39c4, #10239e, #061178, #030852;@themePurple: #fafaff, #f9f0ff, #efdbff, #d3adf7, #b37feb, #9254de, #722ed1,
	#531dab, #391085, #22075e, #120338;@themeMagenta: #fff0f6, #ffd6e7, #ffadd2, #ff85c0, #f759ab, #eb2f96, #c41d7f,
	#9e1068, #780650, #520339;@themeGray: #ffffff, #fafafa, #f5f5f5, #e8e8e8, #d9d9d9, #bfbfbf, #8c8c8c,
	#595959, #262626, #000000;@primaryColor: extract(@themeBlue, 6);@primaryHoverColor: extract(@themeBlue, 5);@primaryActiveColor: extract(@themeBlue, 7);@primaryBgHoverColor: extract(@themeGeekBlue, 1);@primaryBorderColor: extract(@themeBlue, 3);@titleColor: extract(@themeGray, 10);@primaryTextColor: extract(@themeGray, 9);@secondaryTextColor: extract(@themeGray, 8);@disableColor: extract(@themeGray, 6);@secondaryBorderColor: extract(@themeGray, 5);@dividersColor: extract(@themeGray, 4);@secondaryBgColor: extract(@themeGray, 3);@tableHeaderColor: extract(@themeGray, 2);@whiteColor: extract(@themeGray, 1);@primary-color: @primaryColor;@info-color: @primaryColor;@success-color: extract(@themeGreen, 6);@processing-color: @primaryColor;@error-color: extract(@themeRed, 6);@highlight-color: extract(@themeRed, 6);@warning-color: extract(@themeGold, 6);@normal-color: extract(@themeGray, 5);@white: extract(@themeGray, 1);@black: extract(@themeGray, 10);@link-color: extract(@themeBlue, 5);@font-size-base: 14px;@text-color: @primaryTextColor;@heading-color: extract(@themeGray, 10);@text-color-secondary: @secondaryTextColor;@disabled-color: @disableColor;@border-color-base: @secondaryBorderColor;@box-shadow-base: 0 6px 20px 0 rgba(0, 0, 0, 0.15);@border-radius-base: 4px;@item-active-bg: @primaryBgHoverColor;@item-hover-bg: @primaryBgHoverColor;@btn-shadow: none;@btn-primary-shadow: none;@btn-padding-horizontal-base: 18px;@divider-color: @dividersColor;@dropdown-selected-color: @primaryBgHoverColor;@label-color: @primaryTextColor;@select-item-selected-bg: @primaryBgHoverColor;@select-item-active-bg: @primaryBgHoverColor;@select-selection-item-bg: @primaryBgHoverColor;@tooltip-bg: @black;@tooltip-arrow-width: 10px;@popover-arrow-width: 10px;@popover-padding-horizontal: 24px;@rate-star-color: extract(@themeGold, 6);