@import "antd/dist/antd.less";
@import "./Config.less";
@import "./stylesheets/theme.less";
@import "./Util.less";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

svg {
	vertical-align: baseline;
	margin-left: auto;
	margin-right: auto;
}

html,
body,
#root {
	height: 100vh;
	width: 100%;
	outline: none;
	margin: 0px;
	padding: 0px;
	font-family: @themeFontFamily;
	font-size: @themeFontSize;
	color: @themePrimaryTextColor;
	background-color: @themeContainerBackgroundColor;
	--antd-wave-shadow-color: @themePrimaryColor !important;

	// custom scrollbar for IE
	// scrollbar-track-color: none;
	// scrollbar-face-color: @themeDarkGrayColor;
}

#root {
	@apply flex flex-col;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
}

input:-internal-autofill-previewed,
input:-internal-autofill-selected,
textarea:-internal-autofill-previewed,
textarea:-internal-autofill-selected,
select:-internal-autofill-previewed,
select:-internal-autofill-selected {
	background-color: transparent !important;
	color: @themePrimaryTextColor;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
	box-shadow: none !important;
	-webkit-box-shadow: none !important;
	transition: background-color 5000s ease-in-out 0s !important;
	color: @themePrimaryTextColor;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0px;
}

a,
a:hover,
a:active,
a:focus,
a:visited,
a:link {
	color: @themePrimaryColor;
	outline: none !important;
}

button,
button:hover,
button:active,
button:focus {
	outline: none !important;
}

.ant-legacy-form {
	.ant-legacy-form-item {
		@apply items-center;
		&.has-currency-addon-input {
			.ant-legacy-form-item-children {
				display: flex;
				.currency-addon {
					width: 3rem;
					padding: 0 0.5rem;
				}
			}
			.sub-action-button {
				margin-left: 1rem;
			}
		}
		.ant-legacy-form-item-label {
			@apply p-0 overflow-ellipsis;
		}

		.ant-legacy-form-item-label > label {
			color: @themePrimaryTextColor;
		}
	}
}

.section-header {
	font-size: 14px;
	@apply font-medium;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -0.06px;
	color: #5670a1;
	text-transform: uppercase;

	&.email-alias {
		padding-top: 24px;
	}

	&.with-tooltip {
		display: flex;
		align-items: center;
	}
}

.section-sub-header {
	font-size: 14px;
	font-weight: normal;
}

.help-tooltip,
.cancel-tooltip {
	border-radius: 50%;
	color: @themePrimaryColor;
	border: 1px solid @themePrimaryColor;
	width: 24px;
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	cursor: pointer;
}

.ant-dropdown {
	box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15);
	.ant-dropdown-menu {
		min-width: 10.625rem;
		border-radius: 6px;
		box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15);
		background-color: @themeWhiteColor;

		&:not(.no-padding) {
			padding: 0.8rem 0;
		}

		.ant-dropdown-menu-item {
			padding: 0.8rem 1.4rem;
			color: @themePrimaryTextColor;
			font-size: 14px;

			&:hover {
				@apply bg-geekBlue-1;
			}

			&.ant-dropdown-menu-item-selected {
				@apply bg-geekBlue-1;
			}

			&.switch-menu-item {
				padding: 0 0;

				label {
					padding: 0.8rem 1.4rem;
					display: grid;
					grid-gap: 2rem;
					justify-content: space-between;
					grid-auto-flow: column;
					align-items: center;
				}
			}

			&.delete-menu-item {
				color: @themeRedColor;
			}
		}

		.ant-dropdown-menu-item-divider {
			margin: 0px;
		}
	}
}

.zomentum-dropdown {
	.full-width-dropdown {
		li {
			padding: 0 !important;
		}
		.custom-menu-dropdown-full-width {
			display: block;
			padding: 0.8rem 1.4rem;
		}
	}
}

.ant-drawer {
	@media screen and (max-width: 767px) {
		.ant-drawer-content-wrapper {
			width: 100% !important;
		}
	}

	@media screen and (min-width: 768px) and (max-width: 991px) {
		.ant-drawer-content-wrapper {
			width: 75% !important;
		}
	}

	@media screen and (min-width: 992px) {
		.ant-drawer-content-wrapper {
			width: 50% !important;
		}
	}
}

.link {
	color: @themePrimaryColor;
	cursor: pointer;
}

// Set the footer for all the drawers
.zomentum-drawer {
	&.ant-drawer-open {
		.ant-drawer-content-wrapper {
			.ant-drawer-content {
				.ant-drawer-wrapper-body {
					.ant-drawer-body {
						@apply pb-9;
						.ant-drawer-footer {
							position: absolute;
							z-index: 999;
							bottom: 0;
							left: 0;
							right: 0;
							background-color: @themeWhiteColor;
							display: flex;
							justify-content: flex-end;
						}
					}
				}
			}
		}
	}

	.drawer-form-container {
		@apply pb-10;
	}
}

.ant-divider {
	&.ant-divider-horizontal {
		margin: 0px;
	}
}

.ant-popover-content {
	.ant-popover-inner {
		.ant-popover-inner-content {
			box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15) !important;
		}
	}
}

.ant-select-dropdown {
	box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15) !important;
	.add-entity-action {
		padding: 8px 12px;
		border-style: solid none none none;
		border-width: 0.5px;
		border-color: @themeBorderColor;
		color: @themePrimaryColor;
		cursor: pointer;

		&:hover,
		&:active,
		&:focus {
			background: @themeGrayColor;
		}
	}

	.ant-select-dropdown-menu-item {
		&:hover {
			.add-entity-action {
				background: black;
			}
		}
	}
}

.ant-tooltip {
	&.ant-tooltip-placement-right,
	&.ant-tooltip-placement-left {
		.ant-tooltip-content {
			.ant-tooltip-arrow {
				height: 20px;
				.ant-tooltip-arrow-content {
					width: 11px;
					height: 10px;
					@apply bg-gray-10;
				}
			}
		}
	}
	&.ant-tooltip-placement-top,
	&.ant-tooltip-placement-bottom,
	&.ant-tooltip-placement-bottomRight,
	&.ant-tooltip-placement-bottomLeft,
	&.ant-tooltip-placement-topRight,
	&.ant-tooltip-placement-topLeft {
		.ant-tooltip-content {
			.ant-tooltip-arrow {
				width: 20px;
				.ant-tooltip-arrow-content {
					width: 10px;
					height: 10px;
					@apply bg-gray-10;
				}
			}
		}
	}
	.ant-tooltip-inner {
		@apply bg-gray-10 p-2;
		filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
	}
}

.full-width {
	width: 100%;
}

textarea.zomentum-sm-textarea {
	height: unset !important ;
	min-height: 10rem !important ;
}

.bulk-action-modal {
	.ant-modal-content {
		border-radius: 6px;
		.ant-modal-body {
			.ant-modal-confirm-body-wrapper {
				.ant-modal-confirm-title {
					font-size: 1.25rem;
				}
				.ant-modal-confirm-content {
					font-size: 14px;
					color: #8798ad;
				}
				.ant-modal-confirm-btns {
					.ant-btn {
						border-radius: 4px;
					}
				}
			}
		}
	}
}

.ant-tag-magenta,
.ant-tag-red,
.ant-tag-vocano,
.ant-tag-orange,
.ant-tag-gold,
.ant-tag-lime,
.ant-tag-green,
.ant-tag-cyan,
.ant-tag-blue,
.ant-tag-geekblue,
.ant-tag-purple {
	padding-bottom: 1px !important;
}

.ant-tag-green {
	@apply text-green-6 border-green-3 bg-green-1 !important;
}
#fc_frame.fc_l2r,
#fc_frame.fc-widget-normal.fc_l2r {
	@apply bottom-2 !important;
	left: 72px !important;
}

@themeRed: #fff1f0, #ffccc7, #ffa39e, #ff7875, #ff4d4f, #f5222d, #cf1322,
	#a8071a, #820014, #5c0011;@themeVolcano: #fff2e8, #ffd8bf, #ffbb96, #ff9c6e, #ff7a45, #fa541c, #d4380d,
	#ad2102, #871400, #610b00;@themeOrange: #fff7e6, #ffe7ba, #ffd591, #ffc069, #ffa940, #fa8c16, #d46b08,
	#ad4e00, #873800, #612500;@themeGold: #fffbe6, #fff1b8, #ffe58f, #ffd666, #ffc53d, #faad14, #d48806,
	#ad6800, #874d00, #613400;@themeYellow: #feffe6, #ffffb8, #fffb8f, #fff566, #ffec3d, #fadb14, #d4b106,
	#ad8b00, #876800, #614700;@themeLime: #fcffe6, #f4ffb8, #eaff8f, #d3f261, #bae637, #a0d911, #7cb305,
	#5b8c00, #3f6600, #254000;@themeGreen: #f6fef9, #f6ffed, #d9f7be, #b7eb8f, #95de64, #73d13d, #52c41a,
	#389e0d, #237804, #135200, #092b00;@themeCyan: #e6fffb, #b5f5ec, #87e8de, #5cdbd3, #36cfc9, #13c2c2, #08979c,
	#006d75, #00474f, #002329;@themeBlue: #e6f7ff, #bae7ff, #91d5ff, #69c0ff, #4b85ff, #2f70ff, #096dd9,
	#0050b3, #003a8c, #002766;@themeGeekBlue: #f6f9ff, #f0f5ff, #d6e4ff, #adc6ff, #85a5ff, #597ef7, #2f54eb,
	#1d39c4, #10239e, #061178, #030852;@themePurple: #fafaff, #f9f0ff, #efdbff, #d3adf7, #b37feb, #9254de, #722ed1,
	#531dab, #391085, #22075e, #120338;@themeMagenta: #fff0f6, #ffd6e7, #ffadd2, #ff85c0, #f759ab, #eb2f96, #c41d7f,
	#9e1068, #780650, #520339;@themeGray: #ffffff, #fafafa, #f5f5f5, #e8e8e8, #d9d9d9, #bfbfbf, #8c8c8c,
	#595959, #262626, #000000;@primaryColor: extract(@themeBlue, 6);@primaryHoverColor: extract(@themeBlue, 5);@primaryActiveColor: extract(@themeBlue, 7);@primaryBgHoverColor: extract(@themeGeekBlue, 1);@primaryBorderColor: extract(@themeBlue, 3);@titleColor: extract(@themeGray, 10);@primaryTextColor: extract(@themeGray, 9);@secondaryTextColor: extract(@themeGray, 8);@disableColor: extract(@themeGray, 6);@secondaryBorderColor: extract(@themeGray, 5);@dividersColor: extract(@themeGray, 4);@secondaryBgColor: extract(@themeGray, 3);@tableHeaderColor: extract(@themeGray, 2);@whiteColor: extract(@themeGray, 1);@primary-color: @primaryColor;@info-color: @primaryColor;@success-color: extract(@themeGreen, 6);@processing-color: @primaryColor;@error-color: extract(@themeRed, 6);@highlight-color: extract(@themeRed, 6);@warning-color: extract(@themeGold, 6);@normal-color: extract(@themeGray, 5);@white: extract(@themeGray, 1);@black: extract(@themeGray, 10);@link-color: extract(@themeBlue, 5);@font-size-base: 14px;@text-color: @primaryTextColor;@heading-color: extract(@themeGray, 10);@text-color-secondary: @secondaryTextColor;@disabled-color: @disableColor;@border-color-base: @secondaryBorderColor;@box-shadow-base: 0 6px 20px 0 rgba(0, 0, 0, 0.15);@border-radius-base: 4px;@item-active-bg: @primaryBgHoverColor;@item-hover-bg: @primaryBgHoverColor;@btn-shadow: none;@btn-primary-shadow: none;@btn-padding-horizontal-base: 18px;@divider-color: @dividersColor;@dropdown-selected-color: @primaryBgHoverColor;@label-color: @primaryTextColor;@select-item-selected-bg: @primaryBgHoverColor;@select-item-active-bg: @primaryBgHoverColor;@select-selection-item-bg: @primaryBgHoverColor;@tooltip-bg: @black;@tooltip-arrow-width: 10px;@popover-arrow-width: 10px;@popover-padding-horizontal: 24px;@rate-star-color: extract(@themeGold, 6);