.index-module_color-fill-transparent-1__iIVM9 {
  fill: transparent;
}

.index-module_color-red-10__GtKA5 {
  stroke: #5c0011;
}

.index-module_color-fill-stroke-same-red-10__u5GoE {
  fill: #5c0011;
  stroke: #5c0011;
}

.index-module_color-fill-red-10__pqOOR {
  fill: #5c0011;
}

.index-module_color-red-9__ZNGTc {
  stroke: #820014;
}

.index-module_color-fill-stroke-same-red-9__3sLra {
  fill: #820014;
  stroke: #820014;
}

.index-module_color-fill-red-9__8syIL {
  fill: #820014;
}

.index-module_color-red-8__N2v-Z {
  stroke: #a8071a;
}

.index-module_color-fill-stroke-same-red-8__F8u4s {
  fill: #a8071a;
  stroke: #a8071a;
}

.index-module_color-fill-red-8__VqQTG {
  fill: #a8071a;
}

.index-module_color-red-7__-t7GE {
  stroke: #cf1322;
}

.index-module_color-fill-stroke-same-red-7__frtiO {
  fill: #cf1322;
  stroke: #cf1322;
}

.index-module_color-fill-red-7__6WkZl {
  fill: #cf1322;
}

.index-module_color-red-6__4SLjb {
  stroke: #f5222d;
}

.index-module_color-fill-stroke-same-red-6__fbSg7 {
  fill: #f5222d;
  stroke: #f5222d;
}

.index-module_color-fill-red-6__yMBcV {
  fill: #f5222d;
}

.index-module_color-red-5__9XQL6 {
  stroke: #ff4d4f;
}

.index-module_color-fill-stroke-same-red-5__sH5Ct {
  fill: #ff4d4f;
  stroke: #ff4d4f;
}

.index-module_color-fill-red-5__9RT4E {
  fill: #ff4d4f;
}

.index-module_color-red-4__cMN84 {
  stroke: #ff7875;
}

.index-module_color-fill-stroke-same-red-4__3-Xxp {
  fill: #ff7875;
  stroke: #ff7875;
}

.index-module_color-fill-red-4__hZf-H {
  fill: #ff7875;
}

.index-module_color-red-3__jsR3N {
  stroke: #ffa39e;
}

.index-module_color-fill-stroke-same-red-3__Dqi5- {
  fill: #ffa39e;
  stroke: #ffa39e;
}

.index-module_color-fill-red-3__5xBod {
  fill: #ffa39e;
}

.index-module_color-red-2__W1oAB {
  stroke: #ffccc7;
}

.index-module_color-fill-stroke-same-red-2__XjkVg {
  fill: #ffccc7;
  stroke: #ffccc7;
}

.index-module_color-fill-red-2__vIvLG {
  fill: #ffccc7;
}

.index-module_color-red-1__YCg72 {
  stroke: #fff1f0;
}

.index-module_color-fill-stroke-same-red-1__wpF7N {
  fill: #fff1f0;
  stroke: #fff1f0;
}

.index-module_color-fill-red-1__TZCf1 {
  fill: #fff1f0;
}

.index-module_color-volcano-10__C4T5- {
  stroke: #610b00;
}

.index-module_color-fill-stroke-same-volcano-10__38JHi {
  fill: #610b00;
  stroke: #610b00;
}

.index-module_color-fill-volcano-10__Ii-br {
  fill: #610b00;
}

.index-module_color-volcano-9__Xqzzk {
  stroke: #871400;
}

.index-module_color-fill-stroke-same-volcano-9__HXap8 {
  fill: #871400;
  stroke: #871400;
}

.index-module_color-fill-volcano-9__OVJ7Y {
  fill: #871400;
}

.index-module_color-volcano-8__IlzPn {
  stroke: #ad2102;
}

.index-module_color-fill-stroke-same-volcano-8__CWTzi {
  fill: #ad2102;
  stroke: #ad2102;
}

.index-module_color-fill-volcano-8__L1qRE {
  fill: #ad2102;
}

.index-module_color-volcano-7__oyykY {
  stroke: #d4380d;
}

.index-module_color-fill-stroke-same-volcano-7__6-uEA {
  fill: #d4380d;
  stroke: #d4380d;
}

.index-module_color-fill-volcano-7__s1lWe {
  fill: #d4380d;
}

.index-module_color-volcano-6__dTxCE {
  stroke: #fa541c;
}

.index-module_color-fill-stroke-same-volcano-6__-URbO {
  fill: #fa541c;
  stroke: #fa541c;
}

.index-module_color-fill-volcano-6__r5ZL6 {
  fill: #fa541c;
}

.index-module_color-volcano-5__-bJ3T {
  stroke: #ff7a45;
}

.index-module_color-fill-stroke-same-volcano-5__KOIhD {
  fill: #ff7a45;
  stroke: #ff7a45;
}

.index-module_color-fill-volcano-5__uub-6 {
  fill: #ff7a45;
}

.index-module_color-volcano-4__vjm4A {
  stroke: #ff9c6e;
}

.index-module_color-fill-stroke-same-volcano-4__zmmL- {
  fill: #ff9c6e;
  stroke: #ff9c6e;
}

.index-module_color-fill-volcano-4__M78j3 {
  fill: #ff9c6e;
}

.index-module_color-volcano-3__sGkBt {
  stroke: #ffbb96;
}

.index-module_color-fill-stroke-same-volcano-3__Byi52 {
  fill: #ffbb96;
  stroke: #ffbb96;
}

.index-module_color-fill-volcano-3__6-k1v {
  fill: #ffbb96;
}

.index-module_color-volcano-2__W6xen {
  stroke: #ffd8bf;
}

.index-module_color-fill-stroke-same-volcano-2__X5uN4 {
  fill: #ffd8bf;
  stroke: #ffd8bf;
}

.index-module_color-fill-volcano-2__NkT7K {
  fill: #ffd8bf;
}

.index-module_color-volcano-1__--4hp {
  stroke: #fff2e8;
}

.index-module_color-fill-stroke-same-volcano-1__YuWLW {
  fill: #fff2e8;
  stroke: #fff2e8;
}

.index-module_color-fill-volcano-1__jmh-O {
  fill: #fff2e8;
}

.index-module_color-orange-10__vg1Lx {
  stroke: #612500;
}

.index-module_color-fill-stroke-same-orange-10__ZHHTy {
  fill: #612500;
  stroke: #612500;
}

.index-module_color-fill-orange-10__DSCkn {
  fill: #612500;
}

.index-module_color-orange-9__nye-2 {
  stroke: #873800;
}

.index-module_color-fill-stroke-same-orange-9__5teUI {
  fill: #873800;
  stroke: #873800;
}

.index-module_color-fill-orange-9__iqQv5 {
  fill: #873800;
}

.index-module_color-orange-8__x5wxt {
  stroke: #ad4e00;
}

.index-module_color-fill-stroke-same-orange-8__A4HCM {
  fill: #ad4e00;
  stroke: #ad4e00;
}

.index-module_color-fill-orange-8__8Y1C6 {
  fill: #ad4e00;
}

.index-module_color-orange-7__eDsJ0 {
  stroke: #d46b08;
}

.index-module_color-fill-stroke-same-orange-7__--eOu {
  fill: #d46b08;
  stroke: #d46b08;
}

.index-module_color-fill-orange-7__r-5Zt {
  fill: #d46b08;
}

.index-module_color-orange-6__dkC0U {
  stroke: #fa8c16;
}

.index-module_color-fill-stroke-same-orange-6__U3zCj {
  fill: #fa8c16;
  stroke: #fa8c16;
}

.index-module_color-fill-orange-6__7Cn6r {
  fill: #fa8c16;
}

.index-module_color-orange-5__XLlbk {
  stroke: #ffa940;
}

.index-module_color-fill-stroke-same-orange-5__y3a-c {
  fill: #ffa940;
  stroke: #ffa940;
}

.index-module_color-fill-orange-5__-SliD {
  fill: #ffa940;
}

.index-module_color-orange-4__YNisX {
  stroke: #ffc069;
}

.index-module_color-fill-stroke-same-orange-4__VUM7i {
  fill: #ffc069;
  stroke: #ffc069;
}

.index-module_color-fill-orange-4__3-Tap {
  fill: #ffc069;
}

.index-module_color-orange-3__Wbulw {
  stroke: #ffd591;
}

.index-module_color-fill-stroke-same-orange-3__etwCQ {
  fill: #ffd591;
  stroke: #ffd591;
}

.index-module_color-fill-orange-3__Y-DG9 {
  fill: #ffd591;
}

.index-module_color-orange-2__sjxrh {
  stroke: #ffe7ba;
}

.index-module_color-fill-stroke-same-orange-2__tE0C8 {
  fill: #ffe7ba;
  stroke: #ffe7ba;
}

.index-module_color-fill-orange-2__YAgu3 {
  fill: #ffe7ba;
}

.index-module_color-orange-1__DtnVO {
  stroke: #fff7e6;
}

.index-module_color-fill-stroke-same-orange-1__dr4l8 {
  fill: #fff7e6;
  stroke: #fff7e6;
}

.index-module_color-fill-orange-1__GA8mE {
  fill: #fff7e6;
}

.index-module_color-gold-10__kjext {
  stroke: #613400;
}

.index-module_color-fill-stroke-same-gold-10__4oe6H {
  fill: #613400;
  stroke: #613400;
}

.index-module_color-fill-gold-10__BS3sQ {
  fill: #613400;
}

.index-module_color-gold-9__83j83 {
  stroke: #874d00;
}

.index-module_color-fill-stroke-same-gold-9__8tU-s {
  fill: #874d00;
  stroke: #874d00;
}

.index-module_color-fill-gold-9__b-ibp {
  fill: #874d00;
}

.index-module_color-gold-8__vNajR {
  stroke: #ad6800;
}

.index-module_color-fill-stroke-same-gold-8__EMDX- {
  fill: #ad6800;
  stroke: #ad6800;
}

.index-module_color-fill-gold-8__z2OFc {
  fill: #ad6800;
}

.index-module_color-gold-7__HvJvq {
  stroke: #d48806;
}

.index-module_color-fill-stroke-same-gold-7__jnBRk {
  fill: #d48806;
  stroke: #d48806;
}

.index-module_color-fill-gold-7__fWV2C {
  fill: #d48806;
}

.index-module_color-gold-6__ApW2c {
  stroke: #faad14;
}

.index-module_color-fill-stroke-same-gold-6__1IP43 {
  fill: #faad14;
  stroke: #faad14;
}

.index-module_color-fill-gold-6__Fe2gx {
  fill: #faad14;
}

.index-module_color-gold-5__6mG3K {
  stroke: #ffc53d;
}

.index-module_color-fill-stroke-same-gold-5__ReePe {
  fill: #ffc53d;
  stroke: #ffc53d;
}

.index-module_color-fill-gold-5__NHMcC {
  fill: #ffc53d;
}

.index-module_color-gold-4__9UXq5 {
  stroke: #ffd666;
}

.index-module_color-fill-stroke-same-gold-4__wXyti {
  fill: #ffd666;
  stroke: #ffd666;
}

.index-module_color-fill-gold-4__hIjLE {
  fill: #ffd666;
}

.index-module_color-gold-3__TgFBg {
  stroke: #ffe58f;
}

.index-module_color-fill-stroke-same-gold-3__Q-JTc {
  fill: #ffe58f;
  stroke: #ffe58f;
}

.index-module_color-fill-gold-3__LdRzc {
  fill: #ffe58f;
}

.index-module_color-gold-2__rJ5uv {
  stroke: #fff1b8;
}

.index-module_color-fill-stroke-same-gold-2__zYgYD {
  fill: #fff1b8;
  stroke: #fff1b8;
}

.index-module_color-fill-gold-2__HsLnd {
  fill: #fff1b8;
}

.index-module_color-gold-1__tVKtO {
  stroke: #fffbe6;
}

.index-module_color-fill-stroke-same-gold-1__uuht6 {
  fill: #fffbe6;
  stroke: #fffbe6;
}

.index-module_color-fill-gold-1__Oup2n {
  fill: #fffbe6;
}

.index-module_color-yellow-10__93p8G {
  stroke: #614700;
}

.index-module_color-fill-stroke-same-yellow-10__tuqKD {
  fill: #614700;
  stroke: #614700;
}

.index-module_color-fill-yellow-10__jOvpJ {
  fill: #614700;
}

.index-module_color-yellow-9__xVGwm {
  stroke: #876800;
}

.index-module_color-fill-stroke-same-yellow-9__sMlOC {
  fill: #876800;
  stroke: #876800;
}

.index-module_color-fill-yellow-9__vWNIv {
  fill: #876800;
}

.index-module_color-yellow-8__BIlzN {
  stroke: #ad8b00;
}

.index-module_color-fill-stroke-same-yellow-8__N5Bu3 {
  fill: #ad8b00;
  stroke: #ad8b00;
}

.index-module_color-fill-yellow-8__YcWKm {
  fill: #ad8b00;
}

.index-module_color-yellow-7__n7aGB {
  stroke: #d4b106;
}

.index-module_color-fill-stroke-same-yellow-7__W-17z {
  fill: #d4b106;
  stroke: #d4b106;
}

.index-module_color-fill-yellow-7__Ad3gp {
  fill: #d4b106;
}

.index-module_color-yellow-6__--5bs {
  stroke: #fadb14;
}

.index-module_color-fill-stroke-same-yellow-6__uxX-N {
  fill: #fadb14;
  stroke: #fadb14;
}

.index-module_color-fill-yellow-6__-d-vZ {
  fill: #fadb14;
}

.index-module_color-yellow-5__blxBH {
  stroke: #ffec3d;
}

.index-module_color-fill-stroke-same-yellow-5__MUIf5 {
  fill: #ffec3d;
  stroke: #ffec3d;
}

.index-module_color-fill-yellow-5__dzqbd {
  fill: #ffec3d;
}

.index-module_color-yellow-4__hfRpB {
  stroke: #fff566;
}

.index-module_color-fill-stroke-same-yellow-4__jyuGb {
  fill: #fff566;
  stroke: #fff566;
}

.index-module_color-fill-yellow-4__C-ZxG {
  fill: #fff566;
}

.index-module_color-yellow-3__rvNZs {
  stroke: #fffb8f;
}

.index-module_color-fill-stroke-same-yellow-3__fdi99 {
  fill: #fffb8f;
  stroke: #fffb8f;
}

.index-module_color-fill-yellow-3__AVNPG {
  fill: #fffb8f;
}

.index-module_color-yellow-2__bLc07 {
  stroke: #ffffb8;
}

.index-module_color-fill-stroke-same-yellow-2__vWLdH {
  fill: #ffffb8;
  stroke: #ffffb8;
}

.index-module_color-fill-yellow-2__-qrb5 {
  fill: #ffffb8;
}

.index-module_color-yellow-1__MlHCj {
  stroke: #feffe6;
}

.index-module_color-fill-stroke-same-yellow-1__Rckt2 {
  fill: #feffe6;
  stroke: #feffe6;
}

.index-module_color-fill-yellow-1__WYLRA {
  fill: #feffe6;
}

.index-module_color-lime-10__w7rPI {
  stroke: #254000;
}

.index-module_color-fill-stroke-same-lime-10__diFed {
  fill: #254000;
  stroke: #254000;
}

.index-module_color-fill-lime-10__NPE71 {
  fill: #254000;
}

.index-module_color-lime-9__Ucyef {
  stroke: #3f6600;
}

.index-module_color-fill-stroke-same-lime-9__rc6T3 {
  fill: #3f6600;
  stroke: #3f6600;
}

.index-module_color-fill-lime-9__VNbi0 {
  fill: #3f6600;
}

.index-module_color-lime-8__vcXbp {
  stroke: #5b8c00;
}

.index-module_color-fill-stroke-same-lime-8__qb7OA {
  fill: #5b8c00;
  stroke: #5b8c00;
}

.index-module_color-fill-lime-8__f-35c {
  fill: #5b8c00;
}

.index-module_color-lime-7__UtBjk {
  stroke: #7cb305;
}

.index-module_color-fill-stroke-same-lime-7__7a-A- {
  fill: #7cb305;
  stroke: #7cb305;
}

.index-module_color-fill-lime-7__7jZB9 {
  fill: #7cb305;
}

.index-module_color-lime-6__j8P2b {
  stroke: #a0d911;
}

.index-module_color-fill-stroke-same-lime-6__GvpVr {
  fill: #a0d911;
  stroke: #a0d911;
}

.index-module_color-fill-lime-6__FgwRY {
  fill: #a0d911;
}

.index-module_color-lime-5__xOV-M {
  stroke: #bae637;
}

.index-module_color-fill-stroke-same-lime-5__-Lgku {
  fill: #bae637;
  stroke: #bae637;
}

.index-module_color-fill-lime-5__hAN5D {
  fill: #bae637;
}

.index-module_color-lime-4__aPS-O {
  stroke: #d3f261;
}

.index-module_color-fill-stroke-same-lime-4__U6OxC {
  fill: #d3f261;
  stroke: #d3f261;
}

.index-module_color-fill-lime-4__Sk-Cy {
  fill: #d3f261;
}

.index-module_color-lime-3__FcWyD {
  stroke: #eaff8f;
}

.index-module_color-fill-stroke-same-lime-3__E9YCx {
  fill: #eaff8f;
  stroke: #eaff8f;
}

.index-module_color-fill-lime-3__NyrG8 {
  fill: #eaff8f;
}

.index-module_color-lime-2__e2rCu {
  stroke: #f4ffb8;
}

.index-module_color-fill-stroke-same-lime-2__WinGL {
  fill: #f4ffb8;
  stroke: #f4ffb8;
}

.index-module_color-fill-lime-2__nLyP- {
  fill: #f4ffb8;
}

.index-module_color-lime-1__v9cGb {
  stroke: #fcffe6;
}

.index-module_color-fill-stroke-same-lime-1__UNw0r {
  fill: #fcffe6;
  stroke: #fcffe6;
}

.index-module_color-fill-lime-1__20xJi {
  fill: #fcffe6;
}

.index-module_color-green-10__M5-rx {
  stroke: #135200;
}

.index-module_color-fill-stroke-same-green-10__R8gsO {
  fill: #135200;
  stroke: #135200;
}

.index-module_color-fill-green-10__kSttn {
  fill: #135200;
}

.index-module_color-green-9__rEHxM {
  stroke: #237804;
}

.index-module_color-fill-stroke-same-green-9__vYJjD {
  fill: #237804;
  stroke: #237804;
}

.index-module_color-fill-green-9__tNuxM {
  fill: #237804;
}

.index-module_color-green-8__Tnp44 {
  stroke: #389e0d;
}

.index-module_color-fill-stroke-same-green-8__4JGbX {
  fill: #389e0d;
  stroke: #389e0d;
}

.index-module_color-fill-green-8__vdWZq {
  fill: #389e0d;
}

.index-module_color-green-7__f-bVJ {
  stroke: #52c41a;
}

.index-module_color-fill-stroke-same-green-7__Momho {
  fill: #52c41a;
  stroke: #52c41a;
}

.index-module_color-fill-green-7__1SXOC {
  fill: #52c41a;
}

.index-module_color-green-6__V77sR {
  stroke: #73d13d;
}

.index-module_color-fill-stroke-same-green-6__mZxAm {
  fill: #73d13d;
  stroke: #73d13d;
}

.index-module_color-fill-green-6__d-Ysm {
  fill: #73d13d;
}

.index-module_color-green-5__zYMa0 {
  stroke: #95de64;
}

.index-module_color-fill-stroke-same-green-5__B6Zcg {
  fill: #95de64;
  stroke: #95de64;
}

.index-module_color-fill-green-5__decI2 {
  fill: #95de64;
}

.index-module_color-green-4__oZOTB {
  stroke: #b7eb8f;
}

.index-module_color-fill-stroke-same-green-4__PuBlZ {
  fill: #b7eb8f;
  stroke: #b7eb8f;
}

.index-module_color-fill-green-4__mEjRV {
  fill: #b7eb8f;
}

.index-module_color-green-3__20RUL {
  stroke: #d9f7be;
}

.index-module_color-fill-stroke-same-green-3__NgzcT {
  fill: #d9f7be;
  stroke: #d9f7be;
}

.index-module_color-fill-green-3__8uqRA {
  fill: #d9f7be;
}

.index-module_color-green-2__zuz5X {
  stroke: #f6ffed;
}

.index-module_color-fill-stroke-same-green-2__tN1g2 {
  fill: #f6ffed;
  stroke: #f6ffed;
}

.index-module_color-fill-green-2__GFs1O {
  fill: #f6ffed;
}

.index-module_color-green-1__9P83d {
  stroke: #f6fef9;
}

.index-module_color-fill-stroke-same-green-1__mXpJW {
  fill: #f6fef9;
  stroke: #f6fef9;
}

.index-module_color-fill-green-1__OmbW1 {
  fill: #f6fef9;
}

.index-module_color-cyan-10__HIaf3 {
  stroke: #002329;
}

.index-module_color-fill-stroke-same-cyan-10__Arpa9 {
  fill: #002329;
  stroke: #002329;
}

.index-module_color-fill-cyan-10__IxxGt {
  fill: #002329;
}

.index-module_color-cyan-9__GjdSU {
  stroke: #00474f;
}

.index-module_color-fill-stroke-same-cyan-9__D3Ec5 {
  fill: #00474f;
  stroke: #00474f;
}

.index-module_color-fill-cyan-9__ygxYh {
  fill: #00474f;
}

.index-module_color-cyan-8__Vr4x4 {
  stroke: #006d75;
}

.index-module_color-fill-stroke-same-cyan-8__bm4pS {
  fill: #006d75;
  stroke: #006d75;
}

.index-module_color-fill-cyan-8__Q-m9k {
  fill: #006d75;
}

.index-module_color-cyan-7__MaViW {
  stroke: #08979c;
}

.index-module_color-fill-stroke-same-cyan-7__WuAu4 {
  fill: #08979c;
  stroke: #08979c;
}

.index-module_color-fill-cyan-7__2-Ckv {
  fill: #08979c;
}

.index-module_color-cyan-6__Fnh-e {
  stroke: #13c2c2;
}

.index-module_color-fill-stroke-same-cyan-6__0pD6i {
  fill: #13c2c2;
  stroke: #13c2c2;
}

.index-module_color-fill-cyan-6__p4CzR {
  fill: #13c2c2;
}

.index-module_color-cyan-5__8aaxd {
  stroke: #36cfc9;
}

.index-module_color-fill-stroke-same-cyan-5__n3K83 {
  fill: #36cfc9;
  stroke: #36cfc9;
}

.index-module_color-fill-cyan-5__QaSJq {
  fill: #36cfc9;
}

.index-module_color-cyan-4__O-w1D {
  stroke: #5cdbd3;
}

.index-module_color-fill-stroke-same-cyan-4__7DGik {
  fill: #5cdbd3;
  stroke: #5cdbd3;
}

.index-module_color-fill-cyan-4__uttP1 {
  fill: #5cdbd3;
}

.index-module_color-cyan-3__MDhDd {
  stroke: #87e8de;
}

.index-module_color-fill-stroke-same-cyan-3__jXP5D {
  fill: #87e8de;
  stroke: #87e8de;
}

.index-module_color-fill-cyan-3__JZg3N {
  fill: #87e8de;
}

.index-module_color-cyan-2__4Ikux {
  stroke: #b5f5ec;
}

.index-module_color-fill-stroke-same-cyan-2__YznoB {
  fill: #b5f5ec;
  stroke: #b5f5ec;
}

.index-module_color-fill-cyan-2__WGu-p {
  fill: #b5f5ec;
}

.index-module_color-cyan-1__ttM5P {
  stroke: #e6fffb;
}

.index-module_color-fill-stroke-same-cyan-1__5RVqI {
  fill: #e6fffb;
  stroke: #e6fffb;
}

.index-module_color-fill-cyan-1__pYopp {
  fill: #e6fffb;
}

.index-module_color-blue-10__fyO6- {
  stroke: #002766;
}

.index-module_color-fill-stroke-same-blue-10__ivbCK {
  fill: #002766;
  stroke: #002766;
}

.index-module_color-fill-blue-10__Q6u3q {
  fill: #002766;
}

.index-module_color-blue-9__aEhEl {
  stroke: #003a8c;
}

.index-module_color-fill-stroke-same-blue-9__v352K {
  fill: #003a8c;
  stroke: #003a8c;
}

.index-module_color-fill-blue-9__E8Jnj {
  fill: #003a8c;
}

.index-module_color-blue-8__6zhZB {
  stroke: #0050b3;
}

.index-module_color-fill-stroke-same-blue-8__to8Z0 {
  fill: #0050b3;
  stroke: #0050b3;
}

.index-module_color-fill-blue-8__LOy6D {
  fill: #0050b3;
}

.index-module_color-blue-7__6Vkdl {
  stroke: #096dd9;
}

.index-module_color-fill-stroke-same-blue-7__Eokfs {
  fill: #096dd9;
  stroke: #096dd9;
}

.index-module_color-fill-blue-7__cGcJx {
  fill: #096dd9;
}

.index-module_color-blue-6__1XiJg {
  stroke: #2f70ff;
}

.index-module_color-fill-stroke-same-blue-6__qHHG4 {
  fill: #2f70ff;
  stroke: #2f70ff;
}

.index-module_color-fill-blue-6__GESJO {
  fill: #2f70ff;
}

.index-module_color-blue-5__OffWU {
  stroke: #4b85ff;
}

.index-module_color-fill-stroke-same-blue-5__0DoHU {
  fill: #4b85ff;
  stroke: #4b85ff;
}

.index-module_color-fill-blue-5__gD-Rq {
  fill: #4b85ff;
}

.index-module_color-blue-4__3u01N {
  stroke: #69c0ff;
}

.index-module_color-fill-stroke-same-blue-4__W4fag {
  fill: #69c0ff;
  stroke: #69c0ff;
}

.index-module_color-fill-blue-4__DMXPO {
  fill: #69c0ff;
}

.index-module_color-blue-3__m6UtY {
  stroke: #91d5ff;
}

.index-module_color-fill-stroke-same-blue-3__HgKtA {
  fill: #91d5ff;
  stroke: #91d5ff;
}

.index-module_color-fill-blue-3__uvHee {
  fill: #91d5ff;
}

.index-module_color-blue-2__mk7Al {
  stroke: #bae7ff;
}

.index-module_color-fill-stroke-same-blue-2__DWY-w {
  fill: #bae7ff;
  stroke: #bae7ff;
}

.index-module_color-fill-blue-2__96Wbj {
  fill: #bae7ff;
}

.index-module_color-blue-1__NAMAy {
  stroke: #e6f7ff;
}

.index-module_color-fill-stroke-same-blue-1__yceSK {
  fill: #e6f7ff;
  stroke: #e6f7ff;
}

.index-module_color-fill-blue-1__9lfEJ {
  fill: #e6f7ff;
}

.index-module_color-geekBlue-10__I9bkR {
  stroke: #061178;
}

.index-module_color-fill-stroke-same-geekBlue-10__dS8YA {
  fill: #061178;
  stroke: #061178;
}

.index-module_color-fill-geekBlue-10__CYL9q {
  fill: #061178;
}

.index-module_color-geekBlue-9__GyS-b {
  stroke: #10239e;
}

.index-module_color-fill-stroke-same-geekBlue-9__fuP6x {
  fill: #10239e;
  stroke: #10239e;
}

.index-module_color-fill-geekBlue-9__XamS2 {
  fill: #10239e;
}

.index-module_color-geekBlue-8__bI6kQ {
  stroke: #1d39c4;
}

.index-module_color-fill-stroke-same-geekBlue-8__NMP57 {
  fill: #1d39c4;
  stroke: #1d39c4;
}

.index-module_color-fill-geekBlue-8__gpzzg {
  fill: #1d39c4;
}

.index-module_color-geekBlue-7__kOYvP {
  stroke: #2f54eb;
}

.index-module_color-fill-stroke-same-geekBlue-7__VQjS4 {
  fill: #2f54eb;
  stroke: #2f54eb;
}

.index-module_color-fill-geekBlue-7__oylIC {
  fill: #2f54eb;
}

.index-module_color-geekBlue-6__0xox2 {
  stroke: #597ef7;
}

.index-module_color-fill-stroke-same-geekBlue-6__h06YW {
  fill: #597ef7;
  stroke: #597ef7;
}

.index-module_color-fill-geekBlue-6__FOsdx {
  fill: #597ef7;
}

.index-module_color-geekBlue-5__lo-cR {
  stroke: #85a5ff;
}

.index-module_color-fill-stroke-same-geekBlue-5__Oa9pH {
  fill: #85a5ff;
  stroke: #85a5ff;
}

.index-module_color-fill-geekBlue-5__IG3y2 {
  fill: #85a5ff;
}

.index-module_color-geekBlue-4__2TTSG {
  stroke: #adc6ff;
}

.index-module_color-fill-stroke-same-geekBlue-4__fgiXU {
  fill: #adc6ff;
  stroke: #adc6ff;
}

.index-module_color-fill-geekBlue-4__fI4oE {
  fill: #adc6ff;
}

.index-module_color-geekBlue-3__sqU-j {
  stroke: #d6e4ff;
}

.index-module_color-fill-stroke-same-geekBlue-3__UqUmY {
  fill: #d6e4ff;
  stroke: #d6e4ff;
}

.index-module_color-fill-geekBlue-3__vP81y {
  fill: #d6e4ff;
}

.index-module_color-geekBlue-2__W-8Z1 {
  stroke: #f0f5ff;
}

.index-module_color-fill-stroke-same-geekBlue-2__o3OYi {
  fill: #f0f5ff;
  stroke: #f0f5ff;
}

.index-module_color-fill-geekBlue-2__geEPJ {
  fill: #f0f5ff;
}

.index-module_color-geekBlue-1__A9bgk {
  stroke: #f6f9ff;
}

.index-module_color-fill-stroke-same-geekBlue-1__I2ClB {
  fill: #f6f9ff;
  stroke: #f6f9ff;
}

.index-module_color-fill-geekBlue-1__TzLcW {
  fill: #f6f9ff;
}

.index-module_color-purple-10__xdEXX {
  stroke: #22075e;
}

.index-module_color-fill-stroke-same-purple-10__UamvW {
  fill: #22075e;
  stroke: #22075e;
}

.index-module_color-fill-purple-10__4dh-L {
  fill: #22075e;
}

.index-module_color-purple-9__J8HXl {
  stroke: #391085;
}

.index-module_color-fill-stroke-same-purple-9__JtlmP {
  fill: #391085;
  stroke: #391085;
}

.index-module_color-fill-purple-9__KVxPm {
  fill: #391085;
}

.index-module_color-purple-8__YfmQd {
  stroke: #531dab;
}

.index-module_color-fill-stroke-same-purple-8__RT-Kq {
  fill: #531dab;
  stroke: #531dab;
}

.index-module_color-fill-purple-8__fdVV1 {
  fill: #531dab;
}

.index-module_color-purple-7__1PTB7 {
  stroke: #722ed1;
}

.index-module_color-fill-stroke-same-purple-7__D76zR {
  fill: #722ed1;
  stroke: #722ed1;
}

.index-module_color-fill-purple-7__wUePu {
  fill: #722ed1;
}

.index-module_color-purple-6__otvN5 {
  stroke: #9254de;
}

.index-module_color-fill-stroke-same-purple-6__QCr-Q {
  fill: #9254de;
  stroke: #9254de;
}

.index-module_color-fill-purple-6__QA7hh {
  fill: #9254de;
}

.index-module_color-purple-5__DCq6u {
  stroke: #b37feb;
}

.index-module_color-fill-stroke-same-purple-5__GvPQV {
  fill: #b37feb;
  stroke: #b37feb;
}

.index-module_color-fill-purple-5__X1uAj {
  fill: #b37feb;
}

.index-module_color-purple-4__gK5NP {
  stroke: #d3adf7;
}

.index-module_color-fill-stroke-same-purple-4__cIif0 {
  fill: #d3adf7;
  stroke: #d3adf7;
}

.index-module_color-fill-purple-4__LnshN {
  fill: #d3adf7;
}

.index-module_color-purple-3__Ygd3O {
  stroke: #efdbff;
}

.index-module_color-fill-stroke-same-purple-3__t4o3P {
  fill: #efdbff;
  stroke: #efdbff;
}

.index-module_color-fill-purple-3__tDWYK {
  fill: #efdbff;
}

.index-module_color-purple-2__6rbSW {
  stroke: #f9f0ff;
}

.index-module_color-fill-stroke-same-purple-2__nzRZv {
  fill: #f9f0ff;
  stroke: #f9f0ff;
}

.index-module_color-fill-purple-2__7l9bx {
  fill: #f9f0ff;
}

.index-module_color-purple-1__YdT8N {
  stroke: #fafaff;
}

.index-module_color-fill-stroke-same-purple-1__qKp6j {
  fill: #fafaff;
  stroke: #fafaff;
}

.index-module_color-fill-purple-1__WSSlf {
  fill: #fafaff;
}

.index-module_color-magenta-10__xtAJO {
  stroke: #520339;
}

.index-module_color-fill-stroke-same-magenta-10__ghsOi {
  fill: #520339;
  stroke: #520339;
}

.index-module_color-fill-magenta-10__PEgoH {
  fill: #520339;
}

.index-module_color-magenta-9__j8uD4 {
  stroke: #780650;
}

.index-module_color-fill-stroke-same-magenta-9__1pVQE {
  fill: #780650;
  stroke: #780650;
}

.index-module_color-fill-magenta-9__P-CA- {
  fill: #780650;
}

.index-module_color-magenta-8__EzXlR {
  stroke: #9e1068;
}

.index-module_color-fill-stroke-same-magenta-8__n6jaX {
  fill: #9e1068;
  stroke: #9e1068;
}

.index-module_color-fill-magenta-8__xqwim {
  fill: #9e1068;
}

.index-module_color-magenta-7__2tznh {
  stroke: #c41d7f;
}

.index-module_color-fill-stroke-same-magenta-7__dYyCk {
  fill: #c41d7f;
  stroke: #c41d7f;
}

.index-module_color-fill-magenta-7__quN3J {
  fill: #c41d7f;
}

.index-module_color-magenta-6__gDzob {
  stroke: #eb2f96;
}

.index-module_color-fill-stroke-same-magenta-6__Mpvli {
  fill: #eb2f96;
  stroke: #eb2f96;
}

.index-module_color-fill-magenta-6__zGI9u {
  fill: #eb2f96;
}

.index-module_color-magenta-5__zWmQm {
  stroke: #f759ab;
}

.index-module_color-fill-stroke-same-magenta-5__QUNgN {
  fill: #f759ab;
  stroke: #f759ab;
}

.index-module_color-fill-magenta-5__5Z4n8 {
  fill: #f759ab;
}

.index-module_color-magenta-4__mhvsT {
  stroke: #ff85c0;
}

.index-module_color-fill-stroke-same-magenta-4__lN0sY {
  fill: #ff85c0;
  stroke: #ff85c0;
}

.index-module_color-fill-magenta-4__TdHJi {
  fill: #ff85c0;
}

.index-module_color-magenta-3__DqezG {
  stroke: #ffadd2;
}

.index-module_color-fill-stroke-same-magenta-3__RLnKF {
  fill: #ffadd2;
  stroke: #ffadd2;
}

.index-module_color-fill-magenta-3__KH7CR {
  fill: #ffadd2;
}

.index-module_color-magenta-2__nFy2V {
  stroke: #ffd6e7;
}

.index-module_color-fill-stroke-same-magenta-2__a04je {
  fill: #ffd6e7;
  stroke: #ffd6e7;
}

.index-module_color-fill-magenta-2__YpQNz {
  fill: #ffd6e7;
}

.index-module_color-magenta-1__fwLBl {
  stroke: #fff0f6;
}

.index-module_color-fill-stroke-same-magenta-1__ByY9h {
  fill: #fff0f6;
  stroke: #fff0f6;
}

.index-module_color-fill-magenta-1__e52DT {
  fill: #fff0f6;
}

.index-module_color-gray-10__vgqZu {
  stroke: #000000;
}

.index-module_color-fill-stroke-same-gray-10__Vnqo1 {
  fill: #000000;
  stroke: #000000;
}

.index-module_color-fill-gray-10__zYoO7 {
  fill: #000000;
}

.index-module_color-gray-9__4SY0z {
  stroke: #262626;
}

.index-module_color-fill-stroke-same-gray-9__SBLq5 {
  fill: #262626;
  stroke: #262626;
}

.index-module_color-fill-gray-9__90gtI {
  fill: #262626;
}

.index-module_color-gray-8__CEUrV {
  stroke: #595959;
}

.index-module_color-fill-stroke-same-gray-8__WnzKa {
  fill: #595959;
  stroke: #595959;
}

.index-module_color-fill-gray-8__ALpwZ {
  fill: #595959;
}

.index-module_color-gray-7__bTZ7c {
  stroke: #8c8c8c;
}

.index-module_color-fill-stroke-same-gray-7__jOBAv {
  fill: #8c8c8c;
  stroke: #8c8c8c;
}

.index-module_color-fill-gray-7__nJggi {
  fill: #8c8c8c;
}

.index-module_color-gray-6__D9vPh {
  stroke: #bfbfbf;
}

.index-module_color-fill-stroke-same-gray-6__on5EX {
  fill: #bfbfbf;
  stroke: #bfbfbf;
}

.index-module_color-fill-gray-6__nK9f9 {
  fill: #bfbfbf;
}

.index-module_color-gray-5__pYRBR {
  stroke: #d9d9d9;
}

.index-module_color-fill-stroke-same-gray-5__eTL49 {
  fill: #d9d9d9;
  stroke: #d9d9d9;
}

.index-module_color-fill-gray-5__bVhUt {
  fill: #d9d9d9;
}

.index-module_color-gray-4__mt7iT {
  stroke: #e8e8e8;
}

.index-module_color-fill-stroke-same-gray-4__xFeFb {
  fill: #e8e8e8;
  stroke: #e8e8e8;
}

.index-module_color-fill-gray-4__DQwCu {
  fill: #e8e8e8;
}

.index-module_color-gray-3__s43DA {
  stroke: #f5f5f5;
}

.index-module_color-fill-stroke-same-gray-3__pGxIM {
  fill: #f5f5f5;
  stroke: #f5f5f5;
}

.index-module_color-fill-gray-3__cxcy7 {
  fill: #f5f5f5;
}

.index-module_color-gray-2__DD3JU {
  stroke: #fafafa;
}

.index-module_color-fill-stroke-same-gray-2__YFI6f {
  fill: #fafafa;
  stroke: #fafafa;
}

.index-module_color-fill-gray-2__HCqXx {
  fill: #fafafa;
}

.index-module_color-gray-1__IsK2h {
  stroke: #ffffff;
}

.index-module_color-fill-stroke-same-gray-1__SFvE- {
  fill: #ffffff;
  stroke: #ffffff;
}

.index-module_color-fill-gray-1__B8DDA {
  fill: #ffffff;
}

.z-button.ant-btn {
  padding: 0.43rem 1.286rem 0.43rem 1.286rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.z-button svg {
  margin-left: 0px;
  stroke: currentColor;
}

.z-button.ant-btn-dangerous:not(.ant-btn-background-ghost) {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(217, 217, 217, var(--tw-border-opacity));
}

.z-button.ant-btn-dangerous:not(.ant-btn-background-ghost):hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 77, 79, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(255, 77, 79, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.z-button.z-button-left-icon svg {
  margin-right: 8px;
}

.z-button.z-button-right-icon {
  flex-direction: row-reverse;
}

.z-button.z-button-right-icon svg {
  margin-left: 8px;
}

.z-button.z-icon-button {
  padding: initial;
  stroke: initial;
}

.z-button.z-icon-button svg {
  margin: 0px;
}

.z-button.disabled-btn,
.z-button.disabled-btn:hover,
.z-button.disabled-btn:active,
.z-button.disabled-btn:focus {
  text-shadow: none;
  box-shadow: none;
}

.z-button.disabled-btn, .z-button.disabled-btn:hover, .z-button.disabled-btn:active, .z-button.disabled-btn:focus {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity)) !important;
  --tw-border-opacity: 1 !important;
  border-color: rgba(217, 217, 217, var(--tw-border-opacity)) !important;
  cursor: not-allowed !important;
  --tw-text-opacity: 1 !important;
  color: rgba(191, 191, 191, var(--tw-text-opacity)) !important;
}

.z-checkbox .ant-checkbox-inner:after {
  top: 6px;
}

.z-collapse-panel .ant-collapse-header {
  padding-left: 40px !important;
}

.z-collapse-panel .ant-collapse-header .ant-collapse-arrow {
  position: absolute;
  top: 16px;
  left: 16px;
}

.z-collapse-panel .ant-collapse-content-box {
  padding: 0px;
}

.z-comment .ant-comment-content-author {
  display: flex;
  align-items: center;
}

.z-comment .ant-comment-actions {
  display: flex;
  align-items: center;
}

.z-input-number .input-number.ant-input-number-sm .ant-input-number-handler-wrap {
  height: 24px;
}

.z-input-number .input-number.input-number-width-sm {
  width: 3.2rem;
}

.z-input-number .input-number.input-number-width-md {
  width: 4.5rem;
}

.z-input-number .input-number.input-number-width-lg {
  width: 5rem;
}

.z-input-number .input-number.input-number-width-xl {
  width: 7rem;
}

.z-input-number .input-number.input-number-width-full {
  width: calc(100% - 22px);
}

.z-input-number .input-number.input-number-width-full-has-addon {
  flex-grow: 1;
  width: -moz-min-content;
  width: min-content;
}

.z-input-number .input-number .ant-input-number-handler-wrap {
  right: -22px;
  top: -1px;
  height: 2.25rem;
  border-top: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  opacity: 1;
}

.z-input-number .input-number.ant-input-number-disabled .ant-input-number-handler-wrap {
  display: block;
}

.z-input-number .input-number.ant-input-number-disabled .ant-input-number-handler-wrap .ant-input-number-handler {
  cursor: inherit;
}

.z-input-number .input-number.ant-input-number-disabled:hover .ant-input-number-handler-wrap, .z-input-number .input-number.ant-input-number-disabled.ant-input-number-focused .ant-input-number-handler-wrap {
  --tw-border-opacity: 1;
  border-color: rgba(217, 217, 217, var(--tw-border-opacity));
}

.z-input-number .input-number.ant-input-number-disabled:hover.input-number-no-addon .ant-input-number-handler-wrap, .z-input-number .input-number.ant-input-number-disabled.ant-input-number-focused.input-number-no-addon .ant-input-number-handler-wrap {
  --tw-border-opacity: 1;
  border-color: rgba(217, 217, 217, var(--tw-border-opacity));
}

.z-input-number .input-number.input-number-yes-addon .ant-input-number-handler-wrap {
  border-radius: 0px !important;
}

.z-input-number .input-number.input-number-no-addon .ant-input-number-handler-wrap {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.z-input-number .input-number:hover .ant-input-number-handler-wrap,
.z-input-number .input-number.ant-input-number-focused .ant-input-number-handler-wrap {
  border-top-color: #4b85ff;
  border-bottom-color: #4b85ff;
  border-right-color: #4b85ff;
}

.z-input-number .input-number.ant-input-number-focused .ant-input-number-handler-wrap {
  box-shadow: 2px 0 0 2px rgba(47, 112, 255, 0.2) !important;
}

.z-input-number .input-number.ant-input-number-focused.input-number-no-addon .ant-input-number-handler-wrap {
  box-shadow: 2px 0 0 2px rgba(47, 112, 255, 0.2) !important;
}

.z-input-number .add-on-after {
  border-color: #d9d9d9;
  background-color: #f5f5f5;
  margin-left: 21px;
  border-style: solid;
}

.index-module_title__ePyl- {
  color: #000000;
}

.index-module_sectionHeading__2rMQl {
  color: #262626;
}

.index-module_sectionSubheading__OmfWU {
  color: #595959;
}

.index-module_color-red-10__xK9e4 {
  color: #5c0011;
}

.index-module_color-red-9__8INiM {
  color: #820014;
}

.index-module_color-red-8__S9p01 {
  color: #a8071a;
}

.index-module_color-red-7__ZDhZa {
  color: #cf1322;
}

.index-module_color-red-6__FyNnj {
  color: #f5222d;
}

.index-module_color-red-5__XRWiO {
  color: #ff4d4f;
}

.index-module_color-red-4__QioTR {
  color: #ff7875;
}

.index-module_color-red-3__RWavn {
  color: #ffa39e;
}

.index-module_color-red-2__S4cOk {
  color: #ffccc7;
}

.index-module_color-red-1__2k51C {
  color: #fff1f0;
}

.index-module_color-volcano-10__-64bm {
  color: #610b00;
}

.index-module_color-volcano-9__aF64b {
  color: #871400;
}

.index-module_color-volcano-8__CRJND {
  color: #ad2102;
}

.index-module_color-volcano-7__NODDD {
  color: #d4380d;
}

.index-module_color-volcano-6__akAqg {
  color: #fa541c;
}

.index-module_color-volcano-5__JkfED {
  color: #ff7a45;
}

.index-module_color-volcano-4__S66xZ {
  color: #ff9c6e;
}

.index-module_color-volcano-3__8oBxZ {
  color: #ffbb96;
}

.index-module_color-volcano-2__F0XKW {
  color: #ffd8bf;
}

.index-module_color-volcano-1__ducuO {
  color: #fff2e8;
}

.index-module_color-orange-10__aDzXT {
  color: #612500;
}

.index-module_color-orange-9__Jn10- {
  color: #873800;
}

.index-module_color-orange-8__7yrEQ {
  color: #ad4e00;
}

.index-module_color-orange-7__AApTR {
  color: #d46b08;
}

.index-module_color-orange-6__qw5tY {
  color: #fa8c16;
}

.index-module_color-orange-5__DMHoM {
  color: #ffa940;
}

.index-module_color-orange-4__pxYXt {
  color: #ffc069;
}

.index-module_color-orange-3__zchFp {
  color: #ffd591;
}

.index-module_color-orange-2__wWgK- {
  color: #ffe7ba;
}

.index-module_color-orange-1__YfQV4 {
  color: #fff7e6;
}

.index-module_color-gold-10__BIulX {
  color: #613400;
}

.index-module_color-gold-9__pCB0j {
  color: #874d00;
}

.index-module_color-gold-8__2lnld {
  color: #ad6800;
}

.index-module_color-gold-7__e2ZYH {
  color: #d48806;
}

.index-module_color-gold-6__YnGpc {
  color: #faad14;
}

.index-module_color-gold-5__bU1hf {
  color: #ffc53d;
}

.index-module_color-gold-4__bxRj8 {
  color: #ffd666;
}

.index-module_color-gold-3__ZKk4Z {
  color: #ffe58f;
}

.index-module_color-gold-2__JHX5d {
  color: #fff1b8;
}

.index-module_color-gold-1__WjtvR {
  color: #fffbe6;
}

.index-module_color-yellow-10__Da7R- {
  color: #614700;
}

.index-module_color-yellow-9__E-Zf9 {
  color: #876800;
}

.index-module_color-yellow-8__d9c-b {
  color: #ad8b00;
}

.index-module_color-yellow-7__n-YcJ {
  color: #d4b106;
}

.index-module_color-yellow-6__y-GHZ {
  color: #fadb14;
}

.index-module_color-yellow-5__48TDk {
  color: #ffec3d;
}

.index-module_color-yellow-4__mGgOz {
  color: #fff566;
}

.index-module_color-yellow-3__p-KHS {
  color: #fffb8f;
}

.index-module_color-yellow-2__NPHDy {
  color: #ffffb8;
}

.index-module_color-yellow-1__hEjEc {
  color: #feffe6;
}

.index-module_color-lime-10__ydgwA {
  color: #254000;
}

.index-module_color-lime-9__TmJsS {
  color: #3f6600;
}

.index-module_color-lime-8__Bcpic {
  color: #5b8c00;
}

.index-module_color-lime-7__4fPZR {
  color: #7cb305;
}

.index-module_color-lime-6__3-O6c {
  color: #a0d911;
}

.index-module_color-lime-5__CNYI7 {
  color: #bae637;
}

.index-module_color-lime-4__C58bZ {
  color: #d3f261;
}

.index-module_color-lime-3__c80b7 {
  color: #eaff8f;
}

.index-module_color-lime-2__-i8LL {
  color: #f4ffb8;
}

.index-module_color-lime-1__qjnJ0 {
  color: #fcffe6;
}

.index-module_color-green-10__d0VRx {
  color: #135200;
}

.index-module_color-green-9__sdbCQ {
  color: #237804;
}

.index-module_color-green-8__G78-R {
  color: #389e0d;
}

.index-module_color-green-7__qT30q {
  color: #52c41a;
}

.index-module_color-green-6__A02ik {
  color: #73d13d;
}

.index-module_color-green-5__PL4CA {
  color: #95de64;
}

.index-module_color-green-4__5atWk {
  color: #b7eb8f;
}

.index-module_color-green-3__wEgpg {
  color: #d9f7be;
}

.index-module_color-green-2__S2m25 {
  color: #f6ffed;
}

.index-module_color-green-1__7LOa- {
  color: #f6fef9;
}

.index-module_color-cyan-10__xby6R {
  color: #002329;
}

.index-module_color-cyan-9__1Srxo {
  color: #00474f;
}

.index-module_color-cyan-8__59Hqq {
  color: #006d75;
}

.index-module_color-cyan-7__eeLf1 {
  color: #08979c;
}

.index-module_color-cyan-6__wN60X {
  color: #13c2c2;
}

.index-module_color-cyan-5__8aw2g {
  color: #36cfc9;
}

.index-module_color-cyan-4__e7Jji {
  color: #5cdbd3;
}

.index-module_color-cyan-3__b1ZdE {
  color: #87e8de;
}

.index-module_color-cyan-2__VD17d {
  color: #b5f5ec;
}

.index-module_color-cyan-1__jxhcW {
  color: #e6fffb;
}

.index-module_color-blue-10__X20Zf {
  color: #002766;
}

.index-module_color-blue-9__jAuRQ {
  color: #003a8c;
}

.index-module_color-blue-8__BPfdr {
  color: #0050b3;
}

.index-module_color-blue-7__qAmBy {
  color: #096dd9;
}

.index-module_color-blue-6__bVqvI {
  color: #2f70ff;
}

.index-module_color-blue-5__8wifd {
  color: #4b85ff;
}

.index-module_color-blue-4__5PVPz {
  color: #69c0ff;
}

.index-module_color-blue-3__onMEH {
  color: #91d5ff;
}

.index-module_color-blue-2__Ey-Gd {
  color: #bae7ff;
}

.index-module_color-blue-1__TSFJj {
  color: #e6f7ff;
}

.index-module_color-geekBlue-10__CFShg {
  color: #061178;
}

.index-module_color-geekBlue-9__Fnfbk {
  color: #10239e;
}

.index-module_color-geekBlue-8__e4uq3 {
  color: #1d39c4;
}

.index-module_color-geekBlue-7__rZPvu {
  color: #2f54eb;
}

.index-module_color-geekBlue-6__BPPFq {
  color: #597ef7;
}

.index-module_color-geekBlue-5__r2hP4 {
  color: #85a5ff;
}

.index-module_color-geekBlue-4__fcV8p {
  color: #adc6ff;
}

.index-module_color-geekBlue-3__1kzhx {
  color: #d6e4ff;
}

.index-module_color-geekBlue-2__Q7QO6 {
  color: #f0f5ff;
}

.index-module_color-geekBlue-1__Aej2P {
  color: #f6f9ff;
}

.index-module_color-purple-10__SHbVS {
  color: #22075e;
}

.index-module_color-purple-9__nqdRS {
  color: #391085;
}

.index-module_color-purple-8__NCi0q {
  color: #531dab;
}

.index-module_color-purple-7__sKMGX {
  color: #722ed1;
}

.index-module_color-purple-6__anKcZ {
  color: #9254de;
}

.index-module_color-purple-5__wjmv- {
  color: #b37feb;
}

.index-module_color-purple-4__5fDeN {
  color: #d3adf7;
}

.index-module_color-purple-3__AZFIA {
  color: #efdbff;
}

.index-module_color-purple-2__pRRf- {
  color: #f9f0ff;
}

.index-module_color-purple-1__xjAre {
  color: #fafaff;
}

.index-module_color-magenta-10__2YzWM {
  color: #520339;
}

.index-module_color-magenta-9__NlZjH {
  color: #780650;
}

.index-module_color-magenta-8__MHVyU {
  color: #9e1068;
}

.index-module_color-magenta-7__yVhXA {
  color: #c41d7f;
}

.index-module_color-magenta-6__zscSB {
  color: #eb2f96;
}

.index-module_color-magenta-5__pDKhp {
  color: #f759ab;
}

.index-module_color-magenta-4__ckcCd {
  color: #ff85c0;
}

.index-module_color-magenta-3__8sZ-A {
  color: #ffadd2;
}

.index-module_color-magenta-2__fi-Y1 {
  color: #ffd6e7;
}

.index-module_color-magenta-1__vGMTn {
  color: #fff0f6;
}

.index-module_color-gray-10__fpTdz {
  color: #000000;
}

.index-module_color-gray-9__7Ta6U {
  color: #262626;
}

.index-module_color-gray-8__JI8iq {
  color: #595959;
}

.index-module_color-gray-7__6I8R0 {
  color: #8c8c8c;
}

.index-module_color-gray-6__35Zur {
  color: #bfbfbf;
}

.index-module_color-gray-5__uXu7- {
  color: #d9d9d9;
}

.index-module_color-gray-4__7Qtv4 {
  color: #e8e8e8;
}

.index-module_color-gray-3__Zd-Nb {
  color: #f5f5f5;
}

.index-module_color-gray-2__Q9OX5 {
  color: #fafafa;
}

.index-module_color-gray-1__X9frA {
  color: #ffffff;
}

.zModalContainer .ant-modal-header {
  --tw-border-opacity: 1;
  border-color: rgba(232, 232, 232, var(--tw-border-opacity));
  border-style: solid;
  border-top-width: 0px;
  border-right-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 1px;
}

.zModalContainer .ant-modal-title {
  font-weight: 500;
}

.zModalContainer .ant-modal-body {
  padding: 0px;
}

.zModalContainer .modalBody {
  height: 100%;
  padding: 24px;
}

.zModalContainer.hide-borders .ant-modal-header {
  border-bottom-width: 0px;
}

.zModalContainer.hide-borders .ant-modal-footer {
  border-top-width: 0px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
}

.zModalContainer.z-modal-no-padding .ant-modal-content .ant-modal-body {
  padding: 0px;
}

.zModalContainer.z-modal-no-padding .ant-modal-content .ant-modal-body .modalBody {
  padding-left: 0px;
  padding-right: 0px;
}

.zModalContainer.z-modal-no-padding .ant-modal-content .ant-modal-body .modalBody .form-container, .zModalContainer.z-modal-no-padding .ant-modal-content .ant-modal-body .modalBody .form-footer {
  padding-left: 24px;
  padding-right: 24px;
}

.zModalContainer.hide-default-modal-footer .ant-modal-footer {
  display: none;
}

.zModalContainer.modal-size-sm {
  width: 28.57rem !important;
}

.zModalContainer.modal-size-md {
  width: 42.85rem !important;
}

.zModalContainer.modal-size-lg {
  width: 57.14rem !important;
}

.zModalContainer.modal-size-xl {
  width: 71.42rem !important;
}

.zModalContainer .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  margin-left: 16px !important;
}

.z-modal-popups .ant-modal-confirm-btns .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  margin-left: 16px !important;
}

.ant-popover-message {
  display: flex;
  align-items: center;
}

.z-popover {
  border-radius: 0.5rem;
}

.z-popover.size-xs {
  width: 16rem;
}

.z-popover.size-sm {
  width: 20rem;
}

.z-popover.size-rg {
  width: 23rem;
}

.z-popover.size-md {
  width: 35rem;
}

.z-popover.size-lg {
  width: 45rem;
}

.z-popover .ant-popover-content {
  border-radius: 0.125rem;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15);
}

.z-popover .ant-popover-content .ant-popover-inner-content {
  box-shadow: none !important;
  padding: 16px 24px;
}

.z-popover.hide-arrow .ant-popover-content .ant-popover-arrow {
  display: none;
}

.z-popover.z-popover-no-padding .ant-popover-content .ant-popover-inner-content {
  padding: 0px !important;
}

.z-radio-group .radio-children {
  padding-left: 1.785rem;
  font-size: 1rem;
}

.z-radio-group .radio-children-selected-visible {
  visibility: hidden;
}

.z-radio-group.ant-radio-group .ant-radio-wrapper {
  margin-right: 0px;
}

.z-radio-group .ant-radio-wrapper-checked + .radio-children {
  visibility: visible;
}

.addOnAfter {
  border-color: #d9d9d9;
  background-color: #f5f5f5;
  max-width: 6.15rem;
  height: 2.285rem;
}

.multipleSelect .ant-select-selector {
  padding-right: 20px;
}

.z-slider-vertical {
  display: inline-block;
  height: 300px;
  margin-left: 70px;
}

.z-slider:not(.ant-slider-vertical) .ant-slider-rail {
  height: 0.57rem;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(217, 217, 217, var(--tw-border-opacity));
  border-radius: 0px;
  border-style: solid;
  border-width: 1px;
}

.z-slider:not(.ant-slider-vertical) .ant-slider-track {
  height: 0.714rem;
  --tw-bg-opacity: 1;
  background-color: rgba(47, 112, 255, var(--tw-bg-opacity));
  border-radius: 0px;
  transform: translateY(-1px);
}

.z-slider:not(.ant-slider-vertical) .ant-slider-handle {
  border-radius: 0.25rem;
  border-width: 0px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  transform: translate(-50%, -1px);
  width: 1.43rem;
  height: 1.43rem;
}

.z-slider:not(.ant-slider-vertical) .ant-slider-handle:before,
.z-slider:not(.ant-slider-vertical) .ant-slider-handle:after {
  content: "";
  height: 0.75rem;
  top: 0.34rem;
}

.z-slider:not(.ant-slider-vertical) .ant-slider-handle:before, .z-slider:not(.ant-slider-vertical) .ant-slider-handle:after {
  --tw-border-opacity: 1;
  border-color: rgba(217, 217, 217, var(--tw-border-opacity));
  border-style: solid;
  border-width: 1px;
  position: absolute;
}

.z-slider:not(.ant-slider-vertical) .ant-slider-handle:before {
  left: 33.33%;
}

.z-slider:not(.ant-slider-vertical) .ant-slider-handle:after {
  right: 33.33%;
}

.z-slider:not(.ant-slider-vertical) .ant-slider-step .ant-slider-dot {
  position: absolute;
  height: 0.857rem;
  width: 0px;
  --tw-border-opacity: 1;
  border-color: rgba(217, 217, 217, var(--tw-border-opacity));
  border-radius: 0px;
  border-style: solid;
  border-width: 1px;
  margin-left: 0px;
  top: 0.714rem;
}

.z-slider:not(.ant-slider-vertical) .ant-slider-step .ant-slider-dot:last-child {
  margin-left: -2px;
}

.z-slider:not(.ant-slider-vertical) .ant-slider-mark .ant-slider-mark-text {
  top: 0.714rem;
}

.z-slider:not(.ant-slider-vertical).ant-slider-with-marks {
  margin-bottom: 40px;
}

.z-carousel .slick-slide .draggable-slide {
  cursor: grab;
}

.z-carousel .slick-dots-bottom {
  bottom: 0px;
}

.z-carousel .slick-dots-bottom > li > button {
  height: 5px !important;
  --tw-bg-opacity: 1 !important;
  background-color: rgba(47, 112, 255, var(--tw-bg-opacity)) !important;
  cursor: pointer !important;
}

.z-carousel .slick-dots-bottom > li.slick-active > button {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(47, 112, 255, var(--tw-bg-opacity)) !important;
  cursor: default !important;
}

.z-alert.ant-alert-with-description .ant-alert-icon {
  font-size: 25px;
}

.z-beta-tag {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 34, 45, var(--tw-bg-opacity));
  border-radius: 0.5rem;
  border-width: 0px;
  font-weight: 700;
  line-height: 1rem;
  padding-left: 4px;
  padding-right: 4px;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  letter-spacing: 0.1em;
  font-size: 0.57rem;
}

.z-tooltip.ant-tooltip-placement-right .ant-tooltip-content .ant-tooltip-arrow,
.z-tooltip.ant-tooltip-placement-left .ant-tooltip-content .ant-tooltip-arrow {
  height: 20px;
}

.z-tooltip.ant-tooltip-placement-right .ant-tooltip-content .ant-tooltip-arrow .ant-tooltip-arrow-content,
.z-tooltip.ant-tooltip-placement-left .ant-tooltip-content .ant-tooltip-arrow .ant-tooltip-arrow-content {
  width: 11px;
  height: 10px;
  background-color: #000000;
}

.z-tooltip.ant-tooltip-placement-top .ant-tooltip-content .ant-tooltip-arrow,
.z-tooltip.ant-tooltip-placement-bottom .ant-tooltip-content .ant-tooltip-arrow,
.z-tooltip.ant-tooltip-placement-bottomRight .ant-tooltip-content .ant-tooltip-arrow,
.z-tooltip.ant-tooltip-placement-bottomLeft .ant-tooltip-content .ant-tooltip-arrow,
.z-tooltip.ant-tooltip-placement-topRight .ant-tooltip-content .ant-tooltip-arrow,
.z-tooltip.ant-tooltip-placement-topLeft .ant-tooltip-content .ant-tooltip-arrow {
  width: 20px;
}

.z-tooltip.ant-tooltip-placement-top .ant-tooltip-content .ant-tooltip-arrow .ant-tooltip-arrow-content,
.z-tooltip.ant-tooltip-placement-bottom .ant-tooltip-content .ant-tooltip-arrow .ant-tooltip-arrow-content,
.z-tooltip.ant-tooltip-placement-bottomRight .ant-tooltip-content .ant-tooltip-arrow .ant-tooltip-arrow-content,
.z-tooltip.ant-tooltip-placement-bottomLeft .ant-tooltip-content .ant-tooltip-arrow .ant-tooltip-arrow-content,
.z-tooltip.ant-tooltip-placement-topRight .ant-tooltip-content .ant-tooltip-arrow .ant-tooltip-arrow-content,
.z-tooltip.ant-tooltip-placement-topLeft .ant-tooltip-content .ant-tooltip-arrow .ant-tooltip-arrow-content {
  width: 10px;
  height: 10px;
  background-color: #000000;
}

.z-tooltip .ant-tooltip-inner {
  background-color: #000000;
  padding: 8px;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
}

.z-tooltip.z-tooltip-light .ant-tooltip-inner {
  background-color: #ffffff;
  color: #000000;
}

.z-tooltip.z-tooltip-light.ant-tooltip-placement-right .ant-tooltip-content .ant-tooltip-arrow .ant-tooltip-arrow-content,
.z-tooltip.z-tooltip-light.ant-tooltip-placement-left .ant-tooltip-content .ant-tooltip-arrow .ant-tooltip-arrow-content,
.z-tooltip.z-tooltip-light.ant-tooltip-placement-top .ant-tooltip-content .ant-tooltip-arrow .ant-tooltip-arrow-content,
.z-tooltip.z-tooltip-light.ant-tooltip-placement-bottom .ant-tooltip-content .ant-tooltip-arrow .ant-tooltip-arrow-content {
  background-color: #ffffff;
}

.z-tag {
  font-size: 12px;
  line-height: 18px;
}

.z-tag.ant-tag-default {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(217, 217, 217, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(89, 89, 89, var(--tw-text-opacity));
}

.z-tag.ant-tag-processing {
  --tw-bg-opacity: 1;
  background-color: rgba(230, 247, 255, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(145, 213, 255, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(47, 112, 255, var(--tw-text-opacity));
}

.z-tag.ant-tag-warning {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 251, 230, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(255, 229, 143, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(250, 173, 20, var(--tw-text-opacity));
}

.z-tag.ant-tag-success {
  --tw-bg-opacity: 1;
  background-color: rgba(246, 254, 249, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(217, 247, 190, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(115, 209, 61, var(--tw-text-opacity));
}

.z-tag.ant-tag-error {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 241, 240, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(255, 163, 158, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(245, 34, 45, var(--tw-text-opacity));
}
