@import "../../../../Config.less";

.email-thread-message-drawer {
	&.ant-drawer {
		.ant-drawer-content-wrapper {
			width: 75% !important;

			.ant-drawer-content {
				.ant-drawer-wrapper-body {
					.ant-drawer-header {
						padding: 0px;

						.ant-drawer-title {
							.drawer-title-container {
								height: 48px;
								min-height: 48px;
								max-height: 48px;
								display: flex;
								align-items: center;
								margin-bottom: -1px;

								.ant-skeleton {
									.ant-skeleton-content {
										.ant-skeleton-title {
											margin: 0px 24px;
											max-width: 50%;
										}
									}
								}

								.drawer-title {
									margin-left: 16px;
									overflow: hidden;
									text-overflow: ellipsis;
									white-space: nowrap;
									max-width: 75%;
								}
							}
						}

						.ant-drawer-close {
							top: 0px;
							right: 0px;
							height: 48px;
							width: 48px;

							.anticon {
								vertical-align: inherit;
							}
						}
					}

					.ant-drawer-body {
						padding: 0px;

						.email-thread-message-drawer-form {
							.drawer-form-container {
								min-height: calc(100vh - 6.5rem);
								.tox-tinymce {
									@apply border-0 !important;
								}
							}
							.ant-drawer-footer {
								width: 100%;
								display: flex;
								justify-content: space-between;
								align-items: center;
								.footer-activities {
									display: flex;
									width: 31rem;
								}

								.footer-activities-text {
									button {
										color: @themeSecondaryColor;
										display: flex;
										justify-content: space-evenly;
										align-items: center;
										svg {
											margin-right: 0.5rem;
										}
									}

									&.preview-email {
										span {
											color: @themePrimaryColor;
										}
									}

									&.templates-button {
										width: 7.4rem;
										color: @themePrimaryColor;
										display: flex;
										justify-content: space-evenly;
										align-items: center;
										svg {
											color: @themePrimaryColor;
										}
									}
								}

								.more-options-dropdown-button {
									&[ant-click-animating-without-extra-node]:after {
										animation: 0s !important;
										border-color: transparent !important;
									}
									&.ant-btn {
										width: 30px;
										height: 30px;
										padding: 0;
										background-color: @themeBackgroundLightBlue;
										border-color: white !important;
										align-self: center;
									}
								}
								.icon-active {
									button {
										color: @themePrimaryColor !important;
									}
								}

								.z-button-right-icon {
									svg {
										@apply ml-0;
									}
								}
							}
							&.ant-legacy-form {
								.ant-skeleton {
									padding: 12px 24px;
								}

								.ant-legacy-form-item {
									margin-bottom: 0px;
									border-bottom: 1px solid @themeBorderColor;
									display: flex;
									align-items: center;

									&.subject {
										.ant-legacy-form-item-control {
											.ant-legacy-form-item-children {
												.ant-skeleton {
													padding: 0px;
													h3 {
														.ant-skeleton-title {
															margin-top: 8px;
														}
													}
												}
											}
										}
									}

									.ant-legacy-form-item-label {
										padding-left: 16px;
									}

									@media screen and (max-width: 575px) {
										.ant-legacy-form-item-label {
											padding-top: 8px;
										}
									}

									.ant-legacy-form-item-control-wrapper {
										.ant-legacy-form-item-control {
											position: relative;

											&.has-error {
												@apply my-1;
												.ant-legacy-form-explain {
													@apply ml-3;
												}
											}

											.ant-legacy-form-item-children {
												.ant-input {
													border: none;
												}
												//Will remove after deployment
												// .ant-select {
												// 	.ant-select-selector {
												// 		min-width: 15rem;
												// 	}
												// 	.ant-select-selection {
												// 		border: none;
												// 		width: fit-content;

												// 		.ant-select-selection__rendered {
												// 			margin-left: 11px;
												// 			line-height: 32px;

												// 			.ant-select-selection__placeholder {
												// 				margin-left: 1px;
												// 			}

												// 			.ant-select-selection__choice {
												// 				height: 26px;
												// 				line-height: 24px;
												// 				margin-bottom: 3px;
												// 			}
												// 		}
												// 	}
												// }
											}
										}
									}

									&.recipients-to,
									&.recipients-from {
										.ant-legacy-form-item-control-wrapper {
											.ant-legacy-form-item-control {
												.ant-legacy-form-item-children {
													display: flex;
													align-items: center;
													justify-content: space-between;

													.ant-select {
														width: calc(
															100% - 124px
														);
													}

													.recipients-toggle {
														padding-right: 16px;
														@apply flex text-center;

														.margin-left-12px {
															margin-left: 12px;
														}

														.recipients-cc-toggle,
														.recipients-bcc-toggle {
															width: 48px;
															cursor: pointer;

															&.active,
															&:hover {
																color: @themePrimaryColor;
																background-color: @themeListItemHoverColor;
															}
														}
													}
													.sales-automation-send-document-email {
														min-width: 15rem;
													}
												}
											}
										}
									}
									&.recipients-from {
										.ant-col {
											.ant-legacy-form-item-control {
												.ant-legacy-form-item-children {
													.ant-select {
														max-width: max-content;
														@apply mr-3;
														height: 26px;

														.ant-select-selection {
															border: 1px solid
																@themeBorderColor;
															background: @themeListItemHoverColor;
															height: 32px;

															.ant-select-selection__rendered {
																line-height: 24px;

																.ant-select-selection-selected-value {
																	margin-right: 1rem;
																	margin-top: 3px;
																}
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}

						.email-document-message-info-container {
							border-top: 1px solid @themeBorderColor;
							padding: 12px;
							background-color: @themeListItemHoverColor;

							.email-document-message-info {
								margin-left: 16px;
								transform: scale(1.2);
								cursor: pointer;
							}
						}

						.email-thread-message-files-container {
							margin-bottom: 54px;
							padding: 16px 12px;

							.email-thread-message-file-upload-container {
								.ant-upload {
									color: @themePrimaryColor;
									cursor: pointer;
								}

								a[title="Download file"] {
									display: none;
								}

								.ant-upload-list {
									.ant-upload-list-item {
										height: auto;

										&:not(:first-child) {
											margin-top: 16px;
										}

										&.ant-upload-list-item-uploading {
											margin-bottom: 12px;
										}

										.ant-upload-list-item-info {
											border-radius: 4px;
											background-color: @themeBackgroundLightBlue;

											.anticon-loading,
											.anticon-paper-clip {
												top: 10px;
												left: 10px;
											}

											.ant-upload-list-item-card-actions {
												right: 6px;
												top: 6px;
											}
										}

										.anticon-close {
											top: 10px;
											right: 10px;
											font-size: 14px;
											opacity: 1;
										}

										.ant-upload-list-item-progress {
											bottom: -16px;
											padding-left: 8px;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		@media screen and (max-width: 991px) {
			.ant-drawer-content-wrapper {
				width: 100% !important;
			}
		}
	}
}

.email-tracking {
	display: flex;
	align-items: center;
	&:hover {
		cursor: pointer;
	}
	svg {
		margin-right: 5px;
	}
}

.side-padding-15px {
	padding: 0 15px;
}
.active {
	color: @themePrimaryColor;
}

.document-expiration-popover {
	.ant-popover-content {
		.ant-popover-inner {
			max-width: 24rem !important;
			.ant-popover-inner-content {
				padding-bottom: 1rem;
				.document-expiration-popover-wrapper {
					display: grid;
					grid-template-rows: 1fr 1fr 1fr 1fr;
					align-items: center;
					grid-gap: 0.5rem;
					.document-expiration-estimate-date {
						opacity: 0.6;
					}
					.expiration-date-days {
						opacity: 0.6;
						display: grid;
						grid-template-columns: 1fr 1fr;
						width: 15rem;

						align-items: center;
						.ant-input-group-wrapper {
							width: 7rem;
							.ant-input-wrapper {
								input[type="number"] {
									text-align: right;
								}
							}
						}
					}
					.expiration-switch {
						display: flex;
						justify-content: space-between;
						align-items: center;
						width: 11rem;
					}
					.expiration-warning-checkbox-wrapper {
						opacity: 0.6;
						display: grid;
						grid-template-columns: 1fr 11fr;
					}
					.visible {
						opacity: 1;
					}
				}
				.grid-three-rows {
					grid-template-rows: 1fr 1fr 1fr !important;
				}
			}
		}
	}
}

.document-autoreminder-popover {
	.ant-popover-content {
		.ant-popover-inner {
			max-width: 28.5rem !important;
			.ant-popover-inner-content {
				padding-bottom: 1rem;
				.document-autoreminder-settings-wrapper {
					background-color: white;
					display: grid;
					grid-gap: 6px;
					grid-template-rows: repeat(3, minmax(20px, auto));
					.autoreminder-switch {
						display: flex;
						align-items: center;
						span {
							@apply font-medium;
							margin-right: 1rem;
						}
						button {
							width: 30px;
						}
					}
					.autoreminder-select-wrapper {
						opacity: 0.5;
						display: grid;
						height: 3rem;
						align-items: center;
						grid-template-columns: 1.5fr 1fr 2.6fr;
						.ant-dropdown-trigger {
							padding: 0 0.5rem;
						}
					}
					.autoreminder-checkbox-wrapper {
						opacity: 0.5;
						display: grid;
						height: 3rem;
						align-items: center;
						grid-template-columns: 0.5fr 1fr 3fr 4fr;
						grid-gap: 5px;
						.ant-dropdown-trigger {
							padding: 0 0.5rem;
						}
					}
					.visible {
						opacity: 1 !important;
					}
					.autoreminder-info {
						padding-right: 1rem;
						font-size: 0.8rem;
						color: @themeDarkGrayColor;
					}
				}
			}
		}
	}
}

.ant-select-dropdown {
	&.from-email-select-dropdown,
	&.to-email-select-dropdown,
	&.cc-email-select-dropdown,
	&.bcc-email-select-dropdown {
		min-width: 15rem !important;
	}
	&.to-email-select-dropdown,
	&.cc-email-select-dropdown,
	&.bcc-email-select-dropdown {
		max-width: fit-content;
	}
}

.preview-email-modal {
	.ant-modal {
		.ant-modal-content {
			.ant-modal-body {
				min-height: 8rem;
				margin: 1rem;
				max-height: 40rem;
				overflow: auto;
				.preview-email-subject {
					margin-bottom: 1rem;
					font-size: 1rem;
					@apply font-medium;
					border-bottom: 1px solid @themeBorderColor;
					padding-bottom: 0.5rem;
					&:empty {
						border: none;
					}
				}
				.ant-spin-nested-loading {
					@apply border border-solid border-gray-5 rounded-lg p-4;
				}
				.merge-tag {
					background: white !important;
				}
			}
		}
	}
}

.mandatory-merge-tag-modal {
	.ant-modal {
		width: 40rem !important;
		.ant-modal-content {
			.ant-modal-header {
				font-weight: 900;
				border-bottom: 0;
				padding-bottom: 0;
			}
			.ant-modal-body {
				padding-bottom: 0;
			}
			.ant-modal-footer {
				border-top: 0;
			}
		}
	}
}

.validate-email-modal {
	.ant-modal-body {
		display: flex;
		justify-content: center;
	}
	.ant-modal-footer {
		padding: 0;
	}
}

.more-options-dropdown {
	.ant-dropdown-menu {
		@apply py-0 !important;
	}
	.ant-dropdown-menu-item {
		@apply py-3 px-5 !important;
	}
	.menu-templates-label {
		display: flex;
		svg {
			transform: rotate(180deg);
			@apply mr-1 ml-0;
		}
	}
}

.import-email-templates-modal {
	.ant-modal-body {
		.ant-table {
			height: 19rem;
			@apply overflow-auto;
		}
	}
}

.ant-dropdown {
	.menu-item-container {
		max-width: 5rem !important;
		max-height: 15.5rem !important;
		@apply overflow-x-hidden !important;
		.ant-dropdown-menu-item {
			padding: 0.5rem 1rem;
			&:hover {
				@apply bg-geekBlue-1 !important;
			}
			span {
				@apply truncate;
			}
		}
	}
}

.attachment-upload {
	.zUploadModalWrapper {
		width: 100% !important;
		height: 20rem;

		.uppy-DragDrop-container[type="button"] {
			width: 100% !important;
			margin-top: 1rem;
		}
	}
}

@themeRed: #fff1f0, #ffccc7, #ffa39e, #ff7875, #ff4d4f, #f5222d, #cf1322,
	#a8071a, #820014, #5c0011;@themeVolcano: #fff2e8, #ffd8bf, #ffbb96, #ff9c6e, #ff7a45, #fa541c, #d4380d,
	#ad2102, #871400, #610b00;@themeOrange: #fff7e6, #ffe7ba, #ffd591, #ffc069, #ffa940, #fa8c16, #d46b08,
	#ad4e00, #873800, #612500;@themeGold: #fffbe6, #fff1b8, #ffe58f, #ffd666, #ffc53d, #faad14, #d48806,
	#ad6800, #874d00, #613400;@themeYellow: #feffe6, #ffffb8, #fffb8f, #fff566, #ffec3d, #fadb14, #d4b106,
	#ad8b00, #876800, #614700;@themeLime: #fcffe6, #f4ffb8, #eaff8f, #d3f261, #bae637, #a0d911, #7cb305,
	#5b8c00, #3f6600, #254000;@themeGreen: #f6fef9, #f6ffed, #d9f7be, #b7eb8f, #95de64, #73d13d, #52c41a,
	#389e0d, #237804, #135200, #092b00;@themeCyan: #e6fffb, #b5f5ec, #87e8de, #5cdbd3, #36cfc9, #13c2c2, #08979c,
	#006d75, #00474f, #002329;@themeBlue: #e6f7ff, #bae7ff, #91d5ff, #69c0ff, #4b85ff, #2f70ff, #096dd9,
	#0050b3, #003a8c, #002766;@themeGeekBlue: #f6f9ff, #f0f5ff, #d6e4ff, #adc6ff, #85a5ff, #597ef7, #2f54eb,
	#1d39c4, #10239e, #061178, #030852;@themePurple: #fafaff, #f9f0ff, #efdbff, #d3adf7, #b37feb, #9254de, #722ed1,
	#531dab, #391085, #22075e, #120338;@themeMagenta: #fff0f6, #ffd6e7, #ffadd2, #ff85c0, #f759ab, #eb2f96, #c41d7f,
	#9e1068, #780650, #520339;@themeGray: #ffffff, #fafafa, #f5f5f5, #e8e8e8, #d9d9d9, #bfbfbf, #8c8c8c,
	#595959, #262626, #000000;@primaryColor: extract(@themeBlue, 6);@primaryHoverColor: extract(@themeBlue, 5);@primaryActiveColor: extract(@themeBlue, 7);@primaryBgHoverColor: extract(@themeGeekBlue, 1);@primaryBorderColor: extract(@themeBlue, 3);@titleColor: extract(@themeGray, 10);@primaryTextColor: extract(@themeGray, 9);@secondaryTextColor: extract(@themeGray, 8);@disableColor: extract(@themeGray, 6);@secondaryBorderColor: extract(@themeGray, 5);@dividersColor: extract(@themeGray, 4);@secondaryBgColor: extract(@themeGray, 3);@tableHeaderColor: extract(@themeGray, 2);@whiteColor: extract(@themeGray, 1);@primary-color: @primaryColor;@info-color: @primaryColor;@success-color: extract(@themeGreen, 6);@processing-color: @primaryColor;@error-color: extract(@themeRed, 6);@highlight-color: extract(@themeRed, 6);@warning-color: extract(@themeGold, 6);@normal-color: extract(@themeGray, 5);@white: extract(@themeGray, 1);@black: extract(@themeGray, 10);@link-color: extract(@themeBlue, 5);@font-size-base: 14px;@text-color: @primaryTextColor;@heading-color: extract(@themeGray, 10);@text-color-secondary: @secondaryTextColor;@disabled-color: @disableColor;@border-color-base: @secondaryBorderColor;@box-shadow-base: 0 6px 20px 0 rgba(0, 0, 0, 0.15);@border-radius-base: 4px;@item-active-bg: @primaryBgHoverColor;@item-hover-bg: @primaryBgHoverColor;@btn-shadow: none;@btn-primary-shadow: none;@btn-padding-horizontal-base: 18px;@divider-color: @dividersColor;@dropdown-selected-color: @primaryBgHoverColor;@label-color: @primaryTextColor;@select-item-selected-bg: @primaryBgHoverColor;@select-item-active-bg: @primaryBgHoverColor;@select-selection-item-bg: @primaryBgHoverColor;@tooltip-bg: @black;@tooltip-arrow-width: 10px;@popover-arrow-width: 10px;@popover-padding-horizontal: 24px;@rate-star-color: extract(@themeGold, 6);