.z-app-header {
  border-color: #d9d9d9;
}

.z-app-header.ant-layout-header {
  padding: 0px;
  position: sticky;
  top: 0px;
  z-index: 10;
  height: 4.5rem;
  background: #ffffff;
  z-index: 50 !important;
}

.z-app-header.ant-layout-header .header-row {
  height: 4.57rem;
  line-height: 3.28rem;
  grid-template-columns: 12.85rem 1fr 4rem;
  grid-gap: 3rem;
}

.z-app-header.ant-layout-header .header-row.header-row-with-beamer {
  grid-template-columns: 15rem 1fr 7rem;
  grid-gap: 1rem;
}

.z-app-header.ant-layout-header .header-row.header-row-with-beamer.header-with-app-switcher {
  grid-template-columns: 17.85rem 1fr 4rem;
  grid-gap: 1rem;
}

.z-app-header.ant-layout-header .header-row.header-for-mobile {
  grid-template-columns: 4.5rem 1fr !important;
}

.z-app-header.ant-layout-header .header-row.header-for-mobile.header-with-app-switcher {
  grid-template-columns: 8.5rem 1fr !important;
  grid-gap: 1rem;
}

@media screen and (max-width: 1280px) {
  .z-app-header.ant-layout-header .header-row {
    grid-template-columns: 4.5rem 1fr 4rem;
  }

  .z-app-header.ant-layout-header .header-row.header-row-with-beamer {
    grid-template-columns: 4.5rem 1fr 7rem;
  }

  .z-app-header.ant-layout-header .header-row.header-row-with-beamer.header-with-app-switcher {
    grid-template-columns: 8.5rem 1fr 4rem;
    grid-gap: 1rem;
  }
}

.z-app-header.ant-layout-header .zomentum-logo {
  height: 2rem;
  border-color: #d9d9d9;
  cursor: pointer;
}

.z-app-header.ant-layout-header .zomentum-logo a {
  display: flex;
  justify-content: center;
}

.z-app-header.ant-layout-header .header-children .ant-btn.ant-btn-default {
  color: #2f70ff;
  border-color: #2f70ff;
}

.z-app-header.ant-layout-header .header-actions-container .notification-menu-container {
  width: 2.85rem;
}

.z-app-header.ant-layout-header .header-actions-container .notification-menu-container .notification-menu-trigger .notification-count.ant-badge {
  position: absolute;
  top: 0.42rem;
  left: 0.71rem;
}

.z-app-header.ant-layout-header .header-actions-container .notification-menu-container .notification-menu-trigger .notification-count.ant-badge .ant-badge-count {
  background: #f5222d;
}

.z-app-header.ant-layout-header .global-search-tab {
  display: flex;
  margin-left: 16px;
  margin-right: 16px;
  width: 25vw;
}

.z-app-header.ant-layout-header .global-search-tab .global-search-filter {
  width: 33.333333%;
}

.z-app-header.ant-layout-header .global-search-tab .global-search-filter .ant-select-selection {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.z-app-header.ant-layout-header .global-search-tab .global-search-filter .ant-select-selector {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.z-app-header.ant-layout-header .global-search-tab .search-autocomplete {
  width: 75%;
}

.z-app-header.ant-layout-header .global-search-tab .search-autocomplete .ant-select-selection {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 250, var(--tw-bg-opacity));
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  cursor: text;
}

.z-app-header.ant-layout-header .global-search-tab .search-autocomplete .ant-select-selector {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.z-app-header.ant-layout-header .global-search-tab .search-autocomplete .ant-select-selector .ant-select-selection-search input {
  max-width: 51%;
}

.z-app-header.ant-layout-header .global-search-tab .search-autocomplete .ant-select-arrow {
  height: 100%;
  position: absolute;
  top: 32%;
  width: 45%;
}

.z-app-header.ant-layout-header .global-search-tab .search-autocomplete .ant-select-arrow .anticon-down {
  display: none;
}

@media screen and (max-width: 1024px) {
  .z-app-header.ant-layout-header .global-search-tab {
    width: 13.75rem;
  }
}

@media screen and (max-width: 769px) {
  .z-app-header.ant-layout-header .global-search-tab {
    width: 10rem;
  }
}

.index-module_backButtonContainer__sKasx {
  height: 2rem;
  border-color: #d9d9d9;
}

.main-content {
  flex-grow: 1;
  overflow: hidden;
}

.main-content .background {
  background-color: #f5f5f5;
  display: flex;
  height: 100%;
}

.main-content .background.background-not-full-screen {
  padding: 8px;
  padding-left: 5.143rem;
}

.main-content .content {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  height: 100%;
  scroll-behavior: smooth;
  flex-grow: 1;
}

.z-left-side-content .z-page-header-title {
  max-width: 30rem;
}

@media screen and (max-width: 600px) {
  .z-left-side-content .z-page-header-title {
    max-width: 10rem;
  }
}

.email-thread-container {
  height: 100%;
}

.email-thread-container .email-thread-messages-skeleton.ant-skeleton {
  padding: 24px;
}

.email-thread-container .email-thread-message-collapse.ant-collapse .email-thread-message-collapse-item.ant-collapse-item {
  border-bottom: 1px solid #d8d8d8;
}

.email-thread-container .email-thread-message-collapse.ant-collapse .email-thread-message-collapse-item.ant-collapse-item:last-child {
  margin-bottom: 52px;
  border-bottom: none;
}

.email-thread-container .email-thread-message-collapse.ant-collapse .email-thread-message-collapse-item.ant-collapse-item .ant-collapse-header {
  padding: 0px !important;
}

.email-thread-container .email-thread-message-collapse.ant-collapse .email-thread-message-collapse-item.ant-collapse-item .ant-collapse-header .email-thread-message-card.ant-card {
  box-shadow: none;
}

.email-thread-container .email-thread-message-collapse.ant-collapse .email-thread-message-collapse-item.ant-collapse-item .ant-collapse-header .email-thread-message-card.ant-card:hover {
  background-color: #f7f7f7;
}

.email-thread-container .email-thread-message-collapse.ant-collapse .email-thread-message-collapse-item.ant-collapse-item .ant-collapse-header .email-thread-message-card.ant-card .ant-card-meta .ant-card-meta-avatar .ant-avatar {
  margin-top: 4px;
}

.email-thread-container .email-thread-message-collapse.ant-collapse .email-thread-message-collapse-item.ant-collapse-item .ant-collapse-header .email-thread-message-card.ant-card .ant-card-meta .ant-card-meta-detail .ant-card-meta-title {
  margin-bottom: 0px;
  cursor: pointer;
}

.email-thread-container .email-thread-message-collapse.ant-collapse .email-thread-message-collapse-item.ant-collapse-item .ant-collapse-header .email-thread-message-card.ant-card .ant-card-meta .ant-card-meta-detail .ant-card-meta-title .email-thread-message-header {
  white-space: normal;
}

.email-thread-container .email-thread-message-collapse.ant-collapse .email-thread-message-collapse-item.ant-collapse-item .ant-collapse-header .email-thread-message-card.ant-card .ant-card-meta .ant-card-meta-detail .ant-card-meta-title .email-thread-message-header > div {
  width: 100%;
}

.email-thread-container .email-thread-message-collapse.ant-collapse .email-thread-message-collapse-item.ant-collapse-item .ant-collapse-header .email-thread-message-card.ant-card .ant-card-meta .ant-card-meta-detail .ant-card-meta-title .email-thread-message-header .email-thread-message-sender-container {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  --tw-text-opacity: 1;
  color: rgba(38, 38, 38, var(--tw-text-opacity));
}

.email-thread-container .email-thread-message-collapse.ant-collapse .email-thread-message-collapse-item.ant-collapse-item .ant-collapse-header .email-thread-message-card.ant-card .ant-card-meta .ant-card-meta-detail .ant-card-meta-title .email-thread-message-header .email-thread-message-sender-container .email-thread-message-from-name, .email-thread-container .email-thread-message-collapse.ant-collapse .email-thread-message-collapse-item.ant-collapse-item .ant-collapse-header .email-thread-message-card.ant-card .ant-card-meta .ant-card-meta-detail .ant-card-meta-title .email-thread-message-header .email-thread-message-sender-container .email-thread-message-from {
  font-weight: 500;
}

.email-thread-container .email-thread-message-collapse.ant-collapse .email-thread-message-collapse-item.ant-collapse-item .ant-collapse-header .email-thread-message-card.ant-card .ant-card-meta .ant-card-meta-detail .ant-card-meta-title .email-thread-message-header .email-thread-message-timestamp-reply-icon-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
}

.email-thread-container .email-thread-message-collapse.ant-collapse .email-thread-message-collapse-item.ant-collapse-item .ant-collapse-header .email-thread-message-card.ant-card .ant-card-meta .ant-card-meta-detail .ant-card-meta-title .email-thread-message-header .email-thread-message-timestamp-reply-icon-container .email-thread-message-timestamp {
  display: inline-block;
  font-size: 12px;
  line-height: 18px;
  margin-right: 12px;
}

.email-thread-container .email-thread-message-collapse.ant-collapse .email-thread-message-collapse-item.ant-collapse-item .ant-collapse-header .email-thread-message-card.ant-card .ant-card-meta .ant-card-meta-detail .ant-card-meta-title .email-thread-message-header .email-thread-message-timestamp-reply-icon-container .email-thread-message-reply-icon-container {
  --tw-border-opacity: 1;
  border-color: rgba(140, 140, 140, var(--tw-border-opacity));
  border-radius: 0.125rem;
  border-style: solid;
  border-width: 1px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
}

.email-thread-container .email-thread-message-collapse.ant-collapse .email-thread-message-collapse-item.ant-collapse-item .ant-collapse-header .email-thread-message-card.ant-card .ant-card-meta .ant-card-meta-detail .ant-card-meta-title .email-thread-message-header .email-thread-message-timestamp-reply-icon-container .email-thread-message-reply-icon-container .email-thread-message-reply-icon {
  width: 10px;
  height: 10px;
  stroke: currentColor;
  --tw-text-opacity: 1;
  color: rgba(140, 140, 140, var(--tw-text-opacity));
}

.email-thread-container .email-thread-message-collapse.ant-collapse .email-thread-message-collapse-item.ant-collapse-item .ant-collapse-header .email-thread-message-card.ant-card .ant-card-meta .ant-card-meta-detail .ant-card-meta-title .email-thread-message-header .email-thread-message-timestamp-reply-icon-container .email-thread-message-reply-icon-container.disabled {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(191, 191, 191, var(--tw-border-opacity));
}

.email-thread-container .email-thread-message-collapse.ant-collapse .email-thread-message-collapse-item.ant-collapse-item .ant-collapse-header .email-thread-message-card.ant-card .ant-card-meta .ant-card-meta-detail .ant-card-meta-title .email-thread-message-header .email-thread-message-timestamp-reply-icon-container .email-thread-message-reply-icon-container.disabled .email-thread-message-reply-icon {
  --tw-text-opacity: 1;
  color: rgba(191, 191, 191, var(--tw-text-opacity));
}

.email-thread-container .email-thread-message-collapse.ant-collapse .email-thread-message-collapse-item.ant-collapse-item .ant-collapse-header .email-thread-message-card.ant-card .ant-card-meta .ant-card-meta-detail .ant-card-meta-title .email-thread-message-header .email-thread-message-receiver-container {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  --tw-text-opacity: 1;
  color: rgba(38, 38, 38, var(--tw-text-opacity));
}

.email-thread-container .email-thread-message-collapse.ant-collapse .email-thread-message-collapse-item.ant-collapse-item .ant-collapse-header .email-thread-message-card.ant-card .ant-card-meta .ant-card-meta-detail .ant-card-meta-title .email-thread-message-header .email-thread-message-receiver-container .email-thread-message-to-caret {
  align-items: center;
  padding-right: 8px;
  padding-left: 8px;
  --tw-text-opacity: 1;
  color: rgba(38, 38, 38, var(--tw-text-opacity));
}

@media screen and (min-width: 992px) {
  .email-thread-container .email-thread-message-collapse.ant-collapse .email-thread-message-collapse-item.ant-collapse-item .ant-collapse-header .email-thread-message-card.ant-card .ant-card-meta .ant-card-meta-detail .ant-card-meta-title .email-thread-message-header .email-thread-message-timestamp-reply-icon-container {
    text-align: right;
  }
}

@media screen and (max-width: 991px) {
  .email-thread-container .email-thread-message-collapse.ant-collapse .email-thread-message-collapse-item.ant-collapse-item .ant-collapse-header .email-thread-message-card.ant-card .ant-card-meta .ant-card-meta-detail .ant-card-meta-title .email-thread-message-header .email-thread-message-timestamp-reply-icon-container {
    margin: 4px auto;
  }
}

.email-thread-container .email-thread-message-collapse.ant-collapse .email-thread-message-collapse-item.ant-collapse-item .ant-collapse-header .email-thread-message-card.ant-card .ant-card-meta .ant-card-meta-detail .ant-card-meta-title .email-thread-message-header .email-thread-message-snippet-container .email-thread-message-snippet {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  overflow: hidden;
  --tw-text-opacity: 1;
  color: rgba(89, 89, 89, var(--tw-text-opacity));
  text-overflow: ellipsis;
  white-space: nowrap;
}

.email-thread-container .email-thread-message-collapse.ant-collapse .email-thread-message-collapse-item.ant-collapse-item .ant-collapse-content .ant-collapse-content-box {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 24px;
  padding-left: 24px;
}

.email-thread-container .email-thread-message-collapse.ant-collapse .email-thread-message-collapse-item.ant-collapse-item-active .email-thread-message-snippet-container {
  display: none;
}

.email-thread-container .email-thread-message-collapse.ant-collapse .email-thread-message-collapse-item.ant-collapse-item-active .email-thread-message-reply-icon-container {
  display: inline-block;
}

.email-thread-container .email-thread-message-collapse.ant-collapse .email-thread-message-collapse-item.ant-collapse-item-active .email-thread-message-to-caret {
  display: inline-flex;
}

.email-thread-container .email-thread-message-collapse.ant-collapse .email-thread-message-collapse-item:not(.ant-collapse-item-active) .email-thread-message-snippet-container {
  display: inline-block;
}

.email-thread-container .email-thread-message-collapse.ant-collapse .email-thread-message-collapse-item:not(.ant-collapse-item-active) .email-thread-message-receiver-container {
  margin-bottom: 8px;
}

.email-thread-container .email-thread-message-collapse.ant-collapse .email-thread-message-collapse-item:not(.ant-collapse-item-active) .email-thread-message-reply-icon-container, .email-thread-container .email-thread-message-collapse.ant-collapse .email-thread-message-collapse-item:not(.ant-collapse-item-active) .email-thread-message-to-caret {
  display: none;
}

.email-thread-container .email-thread-message-files-list {
  margin-top: 20px;
}

.email-thread-container .email-thread-message-files-list .email-thread-message-file-container {
  height: 62px;
  width: 122px;
  border: 1px solid #d8d8d8;
  background-color: #ffffff;
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 16px;
  position: relative;
}

.email-thread-container .email-thread-message-files-list .email-thread-message-file-container .email-thread-message-file .email-thread-message-file-filename {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 8px;
  padding-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 30px;
}

.email-thread-container .email-thread-message-files-list .email-thread-message-file-container .email-thread-message-file .email-thread-message-file-filesize-filetype-container {
  height: 30px;
  line-height: 30px;
}

.email-thread-container .email-thread-message-files-list .email-thread-message-file-container .email-thread-message-file .email-thread-message-file-filesize-filetype-container .email-thread-message-file-filesize {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 4px;
  padding-right: 4px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.email-thread-container .email-thread-message-files-list .email-thread-message-file-container .email-thread-message-file .email-thread-message-file-filesize-filetype-container .email-thread-message-file-filetype {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 4px;
  padding-right: 4px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: uppercase;
  background-color: #2f70ff;
  color: #ffffff;
}

.email-thread-container .email-thread-message-files-list .email-thread-message-file-container .email-thread-message-file-overlay {
  display: none;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
}

.email-thread-container .email-thread-message-files-list .email-thread-message-file-container:hover .email-thread-message-file-overlay, .email-thread-container .email-thread-message-files-list .email-thread-message-file-container.downloading .email-thread-message-file-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
}

.email-thread-container .email-thread-message-files-list .email-thread-message-file-container:hover .email-thread-message-file-overlay .email-thread-message-file-overlay-icon, .email-thread-container .email-thread-message-files-list .email-thread-message-file-container.downloading .email-thread-message-file-overlay .email-thread-message-file-overlay-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.email-thread-container .email-thread-message-files-list .email-thread-message-file-container:hover .email-thread-message-file-overlay .email-thread-message-file-overlay-icon,
.email-thread-container .email-thread-message-files-list .email-thread-message-file-container.downloading .email-thread-message-file-overlay .email-thread-message-file-overlay-icon {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: #ffffff;
}

.email-thread-container .email-thread-message-files-list .email-thread-message-file-container:hover .email-thread-message-file-overlay .email-thread-message-file-overlay-icon .anticon,
.email-thread-container .email-thread-message-files-list .email-thread-message-file-container.downloading .email-thread-message-file-overlay .email-thread-message-file-overlay-icon .anticon {
  color: #2f70ff;
}

.email-thread-container .email-thread-message-files-list .email-thread-message-file-container:hover .email-thread-message-file-overlay .email-thread-message-file-overlay-icon .anticon, .email-thread-container .email-thread-message-files-list .email-thread-message-file-container.downloading .email-thread-message-file-overlay .email-thread-message-file-overlay-icon .anticon {
  vertical-align: middle;
}

.email-thread-container .email-thread-message-files-list .email-thread-message-file-container:hover .email-thread-message-file-overlay .email-thread-message-file-overlay-icon .anticon,
.email-thread-container .email-thread-message-files-list .email-thread-message-file-container.downloading .email-thread-message-file-overlay .email-thread-message-file-overlay-icon .anticon {
  transform: scale(1.5);
}

.email-thread-message-receiver-dropdown-menu.ant-dropdown-menu {
  width: 300px;
}

.email-thread-message-receiver-dropdown-menu.ant-dropdown-menu .ant-dropdown-menu-item {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
  white-space: normal;
}

.email-thread-message-receiver-dropdown-menu.ant-dropdown-menu .ant-dropdown-menu-item:hover {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: transparent;
}

.email-thread-message-receiver-dropdown-menu.ant-dropdown-menu .ant-dropdown-menu-item .email-thread-message-receiver-section:not(:last-child) {
  margin-bottom: 4px;
}

.z-form {
  overflow-x: hidden;
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */
}

.z-form:not(.z-form-scroll) {
  height: 100%;
}

.z-form .ant-form-item .ant-form-item {
  margin-bottom: 0px;
}

.z-form.z-form-scroll {
  overflow-y: auto;
}

.z-form .form-container {
  height: calc(100% - 3.8rem);
  overflow-y: auto;
}

.z-form .form-container .ant-form-item .ant-form-item {
  margin-bottom: 0px;
}

.z-form .ant-form-item-explain {
  margin-bottom: -20px;
}

.z-form .ant-form-item-with-help {
  margin-bottom: 24px;
}

.z-form .ant-form-item-with-help .ant-form-item-extra {
  margin-top: 12px;
}

.z-form .ant-form-item-label > label {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  white-space: break-spaces;
  height: 36px;
}

.z-form .form-footer {
  background-color: #ffffff;
  position: sticky;
}

.z-form .ant-form-item-label {
  display: flex;
}

.z-form .ant-form-item-label label::after {
  display: none;
}

.z-form .ant-form-item-label.ant-col-24 {
  padding-bottom: 0px;
}

.z-form .ant-form-item-control {
  display: flex;
  justify-content: flex-start;
}

.z-form .ant-form-item-control .ant-form-item-control-input {
  min-height: 2.3rem;
}

.z-form .ant-form-item-control .ant-form-item-extra {
  margin-top: 12px;
  --tw-text-opacity: 1;
  color: rgba(89, 89, 89, var(--tw-text-opacity));
}

.z-form .ant-form-item-has-feedback .ant-form-item-control .ant-form-item-control-input .ant-form-item-control-input-content {
  flex: 0 1 auto;
  margin-right: 24px;
  width: 100%;
}

.z-form .ant-form-item-has-feedback .ant-form-item-control .ant-form-item-control-input .ant-form-item-children-icon {
  right: -4px;
}

.z-form .ant-form-item-has-feedback .ant-form-item-control .ant-form-item-extra {
  margin-top: 12px;
  --tw-text-opacity: 1;
  color: rgba(89, 89, 89, var(--tw-text-opacity));
}

.z-form .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
  padding-right: 0px;
}

.z-form .ant-form-item-has-feedback .ant-select .ant-select-arrow {
  right: 0.8rem;
}

.z-form .ant-form-item-has-feedback .ant-picker {
  padding-right: 8px;
}

.z-form .ant-input-affix-wrapper .ant-input-suffix {
  padding-right: 0px;
}

.z-form .ant-input-affix-wrapper.ant-input-affix-wrapper-textarea-with-clear-btn .ant-input-clear-icon {
  padding-right: 16px;
}

.z-form .antd-form-builder-question-icon {
  margin-bottom: 4px;
  fill: currentColor;
  --tw-text-opacity: 1;
  color: rgba(140, 140, 140, var(--tw-text-opacity));
}

.z-form input::-webkit-outer-spin-button,
.z-form input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.z-form input[type="number"] {
  -moz-appearance: textfield;
}

.orderFormSubmitModalHeight .z-form .ant-form-item-children-icon {
  right: 0px !important;
  width: 20px !important;
}

.app-sider.ant-layout-sider {
  min-height: 100vh;
  overflow: auto;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 50;
  background-color: #000000;
  margin-top: 4.5rem;
  width: 14.3rem;
}

.app-sider.ant-layout-sider.update-banner-visible {
  margin-top: 6.8rem;
}

.app-sider.ant-layout-sider.banner-open {
  margin-top: 6.5rem;
}

.app-sider.ant-layout-sider.update-banner-visible.banner-open {
  margin-top: 8.8rem;
}

.app-sider.ant-layout-sider .ant-layout-sider-children {
  overflow: hidden;
}

.app-sider.ant-layout-sider .ant-layout-sider-children .ant-menu {
  background: transparent;
}

.app-sider.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item {
  opacity: 0.6;
}

.app-sider.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item:after {
  border: none;
}

.app-sider.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item:active {
  background-color: transparent;
}

.app-sider.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item .menu-item-container .mask {
  width: 43px;
}

.app-sider.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item.ant-menu-item-active {
  opacity: 1;
}

.app-sider.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item.ant-menu-item-active::after {
  border-width: 0px;
  margin: auto;
  opacity: 1;
  position: absolute;
  left: 0px;
  background-color: transparent;
  border-left: 6px solid #2f70ff;
  transform: scaleY(1);
  right: unset;
}

.app-sider.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item.ant-menu-item-selected {
  opacity: 1;
  position: relative;
  background-color: transparent;
}

.app-sider.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item.ant-menu-item-selected:after {
  border-width: 0px;
  margin: auto;
  opacity: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  content: "";
  border-left: 6px solid #2f70ff;
  transform: scaleY(1);
}

.app-sider.ant-layout-sider .ant-layout-sider-children .ant-menu .chat-menuitem {
  flex-direction: column;
  position: absolute;
  margin-left: 4px !important;
  margin-right: 4px !important;
  width: 4.1rem !important;
  bottom: 50px;
}

.app-sider.ant-layout-sider .ant-layout-sider-children .ant-menu .chat-menuitem.update-banner-visible,
.app-sider.ant-layout-sider .ant-layout-sider-children .ant-menu .chat-menuitem.banner-open {
  bottom: 83px;
}

.app-sider.ant-layout-sider .ant-layout-sider-children .ant-menu .chat-menuitem.update-banner-visible.banner-open {
  bottom: 105px;
}

.app-sider.ant-layout-sider .ant-layout-sider-children .ant-menu .chat-menuitem .ant-divider {
  opacity: 0.6;
}

.app-sider.ant-layout-sider .ant-layout-sider-children .ant-menu .chat-menuitem.ant-menu-item-active::after {
  border-width: 0px;
}

.app-sider.ant-layout-sider .ant-layout-sider-children .ant-menu .chat-menuitem.ant-menu-item-selected:after {
  border-width: 0px;
}

.app-sider.ant-layout-sider .ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed .ant-menu-item {
  display: flex;
  justify-content: center;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0px;
  width: 48px;
  height: 2.2rem;
  line-height: 2.2rem;
}

.app-sider.ant-layout-sider .ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed .ant-menu-item .menu-item-container {
  display: flex;
  align-items: center;
}

.app-sider.ant-layout-sider .ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed .ant-menu-item.show-beta-tag {
  height: 2.8rem;
  line-height: 2.8rem;
}

.app-sider.ant-layout-sider .ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed .ant-menu-item.show-beta-tag .menu-item-container {
  display: flex;
  align-items: flex-start;
}

.app-sider.ant-layout-sider .ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed .ant-menu-item.show-beta-tag .menu-item-container .z-beta-tag {
  margin-left: -2px;
  bottom: 0px;
}

.settings-sider {
  height: calc(100vh - 4.5rem);
}

.settings-sider.update-banner-visible,
.settings-sider.banner-open {
  height: calc(100vh - 6.8rem);
}

.settings-sider.update-banner-visible.banner-open {
  height: calc(100vh - 8.5rem);
}

.settings-sider.ant-layout-sider {
  background-color: #ffffff;
  margin-left: 4.5rem;
  margin-top: 4.5rem;
  z-index: 100;
}

.settings-sider.ant-layout-sider .ant-layout-sider-children {
  border-style: solid;
  border-width: 0px;
  border-right-width: 1px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-color: #d9d9d9;
}

.settings-sider.ant-layout-sider .ant-layout-sider-children .ant-menu {
  color: #262626;
  border-width: 0px;
}

.settings-sider.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item {
  border-style: solid;
  border-width: 0px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  margin: 0px;
  height: 48px;
  line-height: 48px;
  border-color: #d9d9d9;
}

.settings-sider.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item a {
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
  grid-gap: 0.5rem;
}

.settings-sider.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item:not(.ant-menu-item-selected):not(.ant-menu-item-active) a {
  color: #262626 !important;
}

.settings-sider.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item.ant-menu-item-selected,
.settings-sider.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item.ant-menu-item-active {
  background-color: #f5f5f5;
}

.settings-sider.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item.ant-menu-item-selected, .settings-sider.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item.ant-menu-item-active {
  border-right-width: 1px;
}

.settings-sider.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item.ant-menu-item-selected svg,
.settings-sider.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item.ant-menu-item-active svg {
  stroke: #2f70ff;
}

.settings-sider.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item.fixed-menu-tile {
  pointer-events: none;
  border-style: solid;
  border-width: 0px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  color: #595959 !important;
  background-color: #fafafa !important;
  border-color: #d9d9d9 !important;
}

.settings-sider.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item.fixed-menu-tile:hover {
  cursor: unset;
}

.settings-sider.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item.logout-menu-title {
  border-top-width: 1px;
}

.loading-icon {
  max-height: 48px;
}

.z-table {
  --tw-text-opacity: 1;
  color: rgba(38, 38, 38, var(--tw-text-opacity));
  /* 	.ant-table-body {
		@apply ~"text-1";
	} */
}

.z-table.z-table tbody > tr:hover > td {
  --tw-bg-opacity: 1;
  background-color: rgba(246, 249, 255, var(--tw-bg-opacity));
}

.z-table thead > tr > th {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  font-size: 14px !important;
  line-height: 20px !important;
  letter-spacing: -0.01em !important;
  border-radius: 0px;
  font-weight: 500;
  padding: 16px;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  border-color: #d9d9d9;
  border-style: solid;
  border-top-width: 0px;
  border-right-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 1px;
}

.z-table thead > tr > th.ant-table-column-has-sorters {
  padding: 0px;
}

.z-table thead > tr > th .ant-table-column-sorters {
  padding: 14px;
}

.z-table thead > tr > th .ant-table-column-sorters .ant-table-column-sorter {
  visibility: collapse;
}

.z-table thead > tr > th .ant-checkbox-inner {
  border-radius: 2px;
}

.z-table thead > tr > th:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(246, 249, 255, var(--tw-bg-opacity)) !important;
}

.z-table thead > tr > th .ant-table-filter-trigger {
  height: 54px;
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  margin: 0px !important;
  padding: 0px !important;
  padding-right: 8px !important;
}

.z-table thead > tr > th .ant-table-filter-trigger:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(246, 249, 255, var(--tw-bg-opacity)) !important;
}

.z-table.add-border-top thead > tr > th {
  border-style: solid;
  border-right-width: 0px;
  border-left-width: 0px;
  border-top-width: 2px;
  border-bottom-width: 1px;
}

.z-table.add-bg-header thead > tr > th {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(250, 250, 250, var(--tw-bg-opacity)) !important;
}

.z-table.use-blue-header thead > tr > th {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(246, 249, 255, var(--tw-bg-opacity)) !important;
  border-width: 0px !important;
}

.z-table.use-blue-header thead > tr > th:before {
  width: 0px !important;
}

.z-table.use-blue-header tbody > tr:hover > td {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.z-table.use-blue-header tbody > tr > td {
  padding-left: 8px;
  padding-right: 8px;
}

.z-table.use-blue-header tbody > tr > td:first-child {
  padding-left: 16px !important;
}

.z-table.use-blue-header tbody > tr > td:last-child {
  padding-right: 16px !important;
}

.z-table thead > tr th:first-child {
  border-top-left-radius: 0 !important;
}

.z-table thead > tr th:last-child {
  border-top-right-radius: 0 !important;
}

.z-table tbody > tr > td,
.z-table tfoot > tr > td {
  border-color: #d9d9d9;
  /* 		@apply ~"text-1" border-b border-solid border-t-0 border-r-0 border-l-0 text-gray-10 p-4; */
}

.z-table tbody > tr > td, .z-table tfoot > tr > td {
  border-style: solid;
  border-top-width: 0px;
  border-right-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 1px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  padding: 16px;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.z-table tbody > tr {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.z-table .ant-checkbox-inner {
  border-radius: 2px;
  --tw-border-opacity: 1;
  border-color: rgba(217, 217, 217, var(--tw-border-opacity));
}

.z-table .ant-checkbox-checked:after {
  visibility: visible;
  border-radius: 2px;
}

.z-table .ant-table-tbody > tr.ant-table-row-selected > td {
  background: inherit;
  border-color: #bfbfbf;
}

.z-table .ant-table-tbody > tr:hover.selected td, .z-table .ant-table-tbody > tr:hover td {
  --tw-bg-opacity: 1;
  background-color: rgba(246, 249, 255, var(--tw-bg-opacity));
}

.z-table .ant-table-tbody > tr.selected td {
  --tw-bg-opacity: 1;
  background-color: rgba(246, 249, 255, var(--tw-bg-opacity));
}

.z-table .ant-table-pagination {
  margin: 0px !important;
  padding-top: 16px !important;
}

.z-table .icon-grab {
  cursor: grab;
}

.z-table .ant-table-empty .ant-table-body {
  overflow: hidden !important;
}

.z-table .ant-table-empty .ant-table-body tr:hover.selected td, .z-table .ant-table-empty .ant-table-body tr:hover td {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.z-table .ant-table-empty .ant-table-body tr.selected td {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.z-table .ant-table-cell.ant-table-selection-column {
  padding-left: 8px;
  padding-right: 8px;
}

.draggable-z-table-row {
  --tw-bg-opacity: 1;
  background-color: rgba(246, 249, 255, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(217, 217, 217, var(--tw-border-opacity));
  border-style: solid;
  border-width: 1px;
  display: flex;
  align-items: center;
}

.draggable-z-table-row > td {
  padding: 16px;
}

.draggable-z-table-row > td:not(.drag-visible-z-table-row) {
  visibility: hidden;
}

.draggable-row {
  position: relative;
  z-index: 1000;
}

.z-header-box {
  border: 0px;
  border-top-width: 2px;
}

.z-header-box .add-icon {
  visibility: hidden;
}

.z-header-box:hover .add-icon {
  visibility: visible;
}

.opportunity-card .opportunity-card-container {
  min-height: 8.57rem;
}

.opportunity-card .opportunity-card-container.partner-opportunity-color-code {
  border-left-color: #2f54eb !important;
}

.opportunity-card .opportunity-card-container.vendor-opportunity-color-code {
  border-left-color: #2f54eb !important;
}

.opportunity-card .opportunity-card-container.waiting-opportunity-color-code {
  border-left-color: #13c2c2 !important;
}

.opportunity-card .opportunity-card-container.accepted-opportunity-color-code {
  border-left-color: #73d13d !important;
}

.opportunity-card .opportunity-card-container.self-opportunity-color-code,
.opportunity-card .opportunity-card-container.rejected-opportunity-color-code {
  border-left-color: #e8e8e8 !important;
}

.opportunity-card .opportunity-card-container .shared-status-block.partner-opportunity-color-code > div > span {
  --tw-text-opacity: 1;
  color: rgba(89, 126, 247, var(--tw-text-opacity));
}

.opportunity-card .opportunity-card-container .shared-status-block.partner-opportunity-color-code > div > svg {
  stroke: #2f54eb !important;
}

.opportunity-card .opportunity-card-container .shared-status-block.waiting-opportunity-color-code > div > span {
  --tw-text-opacity: 1;
  color: rgba(19, 194, 194, var(--tw-text-opacity));
}

.opportunity-card .opportunity-card-container .shared-status-block.waiting-opportunity-color-code > div > svg {
  stroke: #13c2c2 !important;
}

.opportunity-card .opportunity-card-container .shared-status-block.accepted-opportunity-color-code > div > span {
  --tw-text-opacity: 1;
  color: rgba(115, 209, 61, var(--tw-text-opacity));
}

.opportunity-card .opportunity-card-container .shared-status-block.accepted-opportunity-color-code > div > svg {
  stroke: #73d13d !important;
}

.opportunity-card .opportunity-card-container .shared-status-block.rejected-opportunity-color-code > div > svg {
  stroke: #f5222d !important;
}

.opportunity-card .opportunity-card-container .shared-status-block.vendor-opportunity-color-code > div > span {
  --tw-text-opacity: 1;
  color: rgba(89, 126, 247, var(--tw-text-opacity));
}

.opportunity-card .opportunity-card-container .shared-status-block.vendor-opportunity-color-code > div > svg {
  stroke: #2f54eb !important;
}

.opportunity-card .opportunity-card-container .shared-status-block.self-opportunity-color-code > div > span {
  --tw-text-opacity: 1;
  color: rgba(140, 140, 140, var(--tw-text-opacity));
}

.opportunity-card .opportunity-card-container .shared-status-block.self-opportunity-color-code > div > svg {
  stroke: #8c8c8c !important;
}

.opportunity-card .opportunity-title-client-container span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.opportunity-card.is-dragging .opportunity-card-container {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-rotate: 3deg;
}

.opportunity-card.is-dragging .opportunity-title-client-container {
  --tw-bg-opacity: 1;
  background-color: rgba(246, 249, 255, var(--tw-bg-opacity));
}

.index-module_kanbanWrapContainer__fnsid {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
  display: grid;
  overflow: auto;
  overscroll-behavior-x: contain;
  gap: 12px;
  grid-auto-flow: column;
  grid-auto-columns: minmax(300px, 1fr);
}

.index-module_kanbanColumnWrap__XWj7d {
  height: calc(100% - 0.25rem) !important;
}

@import "~video.js/dist/video-js.min.css";

.z-video-container {
  position: relative;
}

.z-video-container .z-video-loading {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
  display: inline-block;
  margin: auto;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.z-video.preview-mode .vjs-big-play-button, .z-video.hide-big-button .vjs-big-play-button{
  display: none;
}

.z-video-play-button {
  position: absolute;
  top: 45%;
  left: 45%;
}

.z-video-custom-play-button {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
  display: inline-block;
  margin: auto;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.list-view-table {
  height: calc(100% - 3.8rem);
}

.list-view-table .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-header {
  border-bottom: 1px solid #d8d8d8;
  background-color: #f5f5f5;
}

.list-view-table .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-body {
  height: calc(100vh - 15.6rem);
  padding: 0px;
}

.list-view-table .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-body table .ant-table-tbody tr td .link {
  cursor: pointer;
}

.list-view-table .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-body table .ant-table-tbody tr td .audit-log-title-container {
  display: grid;
  grid-template-columns: 1fr 20px;
}

.list-view-table .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-body table .ant-table-tbody tr td .audit-log-title-container .title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #2f70ff;
  max-width: 10rem;
}

.list-view-table .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-body table .ant-table-tbody tr td .audit-log-title-container .title.disable {
  color: #0c1d33;
}

.list-view-table .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-body table .ant-table-tbody tr td .audit-log-title-container svg {
  margin-left: 0.5rem;
}

.list-view-table .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-body table .ant-table-tbody tr td .audit-logs-description {
  display: flex;
}

.list-view-table .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-body table .ant-table-tbody tr td .audit-logs-description .description {
  width: 16rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.list-view-table .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-body table .ant-table-tbody tr td .audit-logs-description .popover-trigger {
  color: #2f70ff;
}

.list-view-table .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-body table .ant-table-tbody tr td .audit-logs-description .popover-trigger:hover {
  cursor: pointer;
}

.list-view-table .footer {
  border-top: 1px solid #d8d8d8;
}

.list-view-table .footer .audit-logs-pagination .ant-btn {
  margin-left: 1rem;
}

.list-view-table .footer .audit-logs-pagination .ant-btn.first-page {
  margin-right: 1rem;
}

.list-view-table .records-selection {
  position: absolute;
  bottom: 12px;
}

.audit-log-popover .ant-popover-inner-content {
  max-height: 320px;
  overflow: auto;
}

.z-render-custom-fields .ant-form-item-label {
  text-align: left;
}

.edit-opportunity-cost-revenue-field {
  max-width: 75% !important;
  width: 75% !important;
}

.edit-opportunity-cost-revenue-field .ant-cascader-menu {
  height: auto !important;
  max-height: 180px !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.edit-opportunity-cost-revenue-field .mce-content-body {
  height: 100%;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
}

.edit-opportunity-cost-revenue-field.disabled-field .mce-content-body {
  --tw-shadow: 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  --tw-text-opacity: 1 !important;
  color: rgba(191, 191, 191, var(--tw-text-opacity)) !important;
}

.cost-revenue-field-currency-addon {
  padding: 4px;
  width: 25% !important;
}

.z-change-suggestion-updates-bar {
  --tw-bg-opacity: 1;
  background-color: rgba(246, 249, 255, var(--tw-bg-opacity));
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -12px;
  margin-right: -12px;
  margin-bottom: 16px;
  margin-top: -12px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
}

.z-change-suggestion-updates-modal .modalBody {
  height: 488px;
  overflow: auto;
}

.z-change-suggestion-updates-modal .ant-modal-footer {
  display: flex;
  align-items: center;
}

.z-change-suggestion-updates-modal .z-change-suggestion-updates-modal-table thead > tr > th {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(246, 249, 255, var(--tw-bg-opacity)) !important;
  border-width: 0px !important;
}

.z-change-suggestion-updates-modal .z-change-suggestion-updates-modal-table thead > tr > th:before {
  width: 0px !important;
}

.z-change-suggestion-updates-table-modal .modalBody {
  height: 488px;
  overflow: auto;
}

.z-change-suggestion-updates-table-modal .z-change-suggestion-updates-table thead > tr > th {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(246, 249, 255, var(--tw-bg-opacity)) !important;
  border-width: 0px !important;
}

.z-change-suggestion-updates-table-modal .z-change-suggestion-updates-table thead > tr > th:before {
  width: 0px !important;
}

.z-change-suggestion-updates-table-modal .z-change-suggestion-updates-table thead > tr > th:last-child {
  text-align: center;
}

.z-change-suggestion-updates-table-modal .z-change-suggestion-updates-table tbody > tr > td:last-child {
  text-align: center;
}

.z-csv-renderer-table .ant-table-cell-ellipsis {
  min-width: 152px;
}

.z-filter-list-page-view-selector {
  width: 156px;
}

.z-filter-list-page-view-selector .ant-select-selector {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.z-filter-list-page-view-save-view-btn {
  border-width: 1px;
}

.filter-list-container .filter-list-item .ant-dropdown-menu-title-content {
  width: 100%;
}

.save-view-modal-radio-group .ant-space-vertical {
  width: 100%;
}

.save-view-modal-radio-group .ant-space-item {
  margin-bottom: 8px;
  width: 100%;
}

.save-view-modal-radio-group label.ant-radio-wrapper {
  width: inherit !important;
  word-break: break-all;
}

.zModalContainer.saveViewModal {
  width: 32rem !important;
}

.ant-popover.guide-popup .ant-popover-content .ant-popover-inner-content, .ant-popover.guide-tooltip .ant-popover-content .ant-popover-inner-content {
  padding: 0px;
}

.ant-popover.guide-popup.ant-popover-placement-rightTop, .ant-popover.guide-popup.ant-popover-placement-rightBottom, .ant-popover.guide-popup.ant-popover-placement-right {
  padding-left: 24px;
}

.ant-popover.guide-popup.ant-popover-placement-leftTop, .ant-popover.guide-popup.ant-popover-placement-leftBottom, .ant-popover.guide-popup.ant-popover-placement-left {
  padding-right: 24px;
}

.ant-popover.guide-popup .ant-popover-arrow {
  display: none;
}

.ant-popover.guide-popup .ant-popover-inner {
  border-radius: 0.5rem;
}

.ant-card.guide-popup-content {
  border-radius: 0.5rem;
}

.ant-card.guide-popup-content .ant-card-cover {
  border-radius: 0.5rem;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  height: 222px;
}

.ant-card.guide-popup-content .ant-card-cover .z-video-container {
  height: 100%;
}

.ant-card.guide-popup-content .ant-card-cover .z-video-container video {
  border-radius: 0.5rem;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  height: 100%;
  width: 100%;
}

.ant-card.guide-popup-content .ant-card-body {
  border-radius: 0.5rem;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  padding: 0px;
}

.ant-card.guide-tooltip-content {
  border-radius: 0.25rem;
  --tw-bg-opacity: 1;
  background-color: rgba(6, 17, 120, var(--tw-bg-opacity));
}

.ant-card.guide-tooltip-content .ant-card-body {
  padding: 0px;
}

.ant-popover.guide-tooltip {
  width: 18rem;
}

.ant-popover.guide-tooltip.ant-popover-placement-left,
.ant-popover.guide-tooltip.ant-popover-placement-right {
  width: 19rem;
}

.ant-popover.guide-tooltip .ant-popover-arrow {
  width: 0.7rem;
  height: 24px;
}

.ant-popover.guide-tooltip .ant-popover-arrow .ant-popover-arrow-content {
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='28' viewBox='0 0 8 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 7.2C5.76731 7.2 7.2 5.76731 7.2 4C7.2 2.23269 5.76731 0.799999 4 0.799999C2.23269 0.799999 0.8 2.23269 0.8 4C0.8 5.76731 2.23269 7.2 4 7.2ZM4.6 28L4.6 4H3.4L3.4 28H4.6Z' fill='%23030852'/%3E%3C/svg%3E%0A");
  width: 0.7rem;
  background-color: transparent;
  background-repeat: no-repeat;
  height: 24px;
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transform: none;
}

.ant-popover.guide-tooltip.ant-popover-placement-top .ant-popover-arrow,
.ant-popover.guide-tooltip.ant-popover-placement-topRight .ant-popover-arrow,
.ant-popover.guide-tooltip.ant-popover-placement-topLeft .ant-popover-arrow {
  bottom: -0.7rem;
}

.ant-popover.guide-tooltip.ant-popover-placement-top .ant-popover-arrow .ant-popover-arrow-content,
.ant-popover.guide-tooltip.ant-popover-placement-topRight .ant-popover-arrow .ant-popover-arrow-content,
.ant-popover.guide-tooltip.ant-popover-placement-topLeft .ant-popover-arrow .ant-popover-arrow-content {
  transform: rotate(180deg);
}

.ant-popover.guide-tooltip.ant-popover-placement-bottom .ant-popover-arrow,
.ant-popover.guide-tooltip.ant-popover-placement-bottomLeft .ant-popover-arrow {
  top: -0.7rem;
}

.ant-popover.guide-tooltip.ant-popover-placement-left .ant-popover-arrow {
  right: 0.3rem;
  top: calc(50% - 8px);
  transform: rotate(90deg);
  transform-origin: right;
}

.ant-popover.guide-tooltip.ant-popover-placement-right .ant-popover-arrow {
  top: calc(50% - 8px);
  transform: rotate(-90deg);
  left: 4px;
  transform-origin: left;
}

.ant-popover.guide-tooltip .ant-popover-inner {
  border-radius: 0.5rem;
}

.highlight-mask-overlay {
  z-index: 920;
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  pointer-events: none;
  position: fixed;
  top: 0px;
  left: 0px;
}

.zcard-header {
  align-items: center;
  justify-content: space-between;
}

.zcard-header .z-input {
  width: 160px;
}

.zcard-header .z-input .ant-input-affix-wrapper {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  height: 24px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 4px;
  padding-left: 4px;
}

.zcard-header .z-input .ant-input {
  font-size: 12px;
  line-height: 18px;
}

.client-contact-card {
  --tw-border-opacity: 1;
  border-color: rgba(217, 217, 217, var(--tw-border-opacity));
}

.z-view-client-contacts-request-modal .modalBody {
  padding-top: 16px;
}

.z-view-client-contacts-request-modal .modalBody .z-input {
  width: 216px;
}

.z-view-client-contacts-request-modal .modalBody .z-input .ant-input-affix-wrapper {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.z-view-client-contacts-request-modal .modalBody .z-input .ant-input-affix-wrapper .ant-input-suffix {
  display: none;
}

.z-view-client-contacts-request-modal .modalBody .z-input .ant-input-affix-wrapper .ant-input-prefix {
  order: 3;
}

/* BASICS */

.CodeMirror {
  /* Set height, width, borders, and global font properties here */
  font-family: 'source-code-pro', Menlo, 'Courier New', Consolas, monospace;
  color: black;
  direction: ltr;
  font-size: 14px;
  z-index: 0;
  border-radius: 5px;
  height: auto;
  background: transparent;
}

/* PADDING */

.CodeMirror-lines {
  padding: 4px 0; /* Vertical padding around content */
}

.CodeMirror pre.CodeMirror-line,
  .CodeMirror pre.CodeMirror-line-like {
  padding: 0 4px; /* Horizontal padding of content */
}

.CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
  background-color: white; /* The little square between H and V scrollbars */
}

/* GUTTER */

.CodeMirror-gutters {
  border-right: 1px solid #ddd;
  background-color: #f7f7f7;
  white-space: nowrap;
  height: initial !important;
}

.CodeMirror-linenumber {
  padding: 0 3px 0 5px;
  min-width: 20px;
  text-align: right;
  color: #999;
  white-space: nowrap;
}

.CodeMirror-guttermarker { color: black;
}

.CodeMirror-guttermarker-subtle { color: #999;
}

/* CURSOR */

.CodeMirror-cursor {
  border-left: 1px solid black;
  border-right: none;
  width: 0;
}

/* Shown when moving in bi-directional text */

.CodeMirror div.CodeMirror-secondarycursor {
  border-left: 1px solid silver;
}

.cm-fat-cursor .CodeMirror-cursor {
  width: auto;
  border: 0 !important;
  background: #7e7;
}

.cm-fat-cursor div.CodeMirror-cursors {
  z-index: 1;
}

.cm-fat-cursor-mark {
  background-color: rgba(20, 255, 20, 0.5);
  animation: blink 1.06s steps(1) infinite;
}

.cm-animate-fat-cursor {
  width: auto;
  border: 0;
  animation: blink 1.06s steps(1) infinite;
  background-color: #7e7;
}

@keyframes blink {
  0% {
  }

  50% { background-color: transparent;
  }

  100% {
  }
}

.cm-tab { display: inline-block; text-decoration: inherit;
}

.CodeMirror-rulers {
  position: absolute;
  left: 0; right: 0; top: -50px; bottom: 0;
  overflow: hidden;
}

.CodeMirror-ruler {
  border-left: 1px solid #ccc;
  top: 0; bottom: 0;
  position: absolute;
}

/* DEFAULT THEME */

.cm-s-default .cm-header {
  color: blue;
}

.cm-s-default .cm-quote {
  color: #090;
}

.cm-negative {
  color: #d44;
}

.cm-positive {
  color: #292;
}

.cm-header, .cm-strong {
  font-weight: bold;
}

.cm-em {
  font-style: italic;
}

.cm-link {
  text-decoration: underline;
}

.cm-strikethrough {
  text-decoration: line-through;
}

.cm-s-default .cm-keyword {
  color: #708;
}

.cm-s-default .cm-atom {
  color: #219;
}

.cm-s-default .cm-number {
  color: #164;
}

.cm-s-default .cm-def {
  color: #00f;
}

.cm-s-default .cm-variable-2 {
  color: #05a;
}

.cm-s-default .cm-variable-3, .cm-s-default .cm-type {
  color: #085;
}

.cm-s-default .cm-comment {
  color: #a50;
}

.cm-s-default .cm-string {
  color: #a11;
}

.cm-s-default .cm-string-2 {
  color: #f50;
}

.cm-s-default .cm-meta {
  color: #555;
}

.cm-s-default .cm-qualifier {
  color: #555;
}

.cm-s-default .cm-builtin {
  color: #30a;
}

.cm-s-default .cm-bracket {
  color: #997;
}

.cm-s-default .cm-tag {
  color: #170;
}

.cm-s-default .cm-attribute {
  color: #00c;
}

.cm-s-default .cm-hr {
  color: #999;
}

.cm-s-default .cm-link {
  color: #00c;
}

.cm-s-default .cm-error {
  color: #f00;
}

.cm-invalidchar {
  color: #f00;
}

.CodeMirror-composing { border-bottom: 2px solid;
}

/* Default styles for common addons */

div.CodeMirror span.CodeMirror-matchingbracket {
  color: #0b0;
}

div.CodeMirror span.CodeMirror-nonmatchingbracket {
  color: #a22;
}

.CodeMirror-matchingtag { background: rgba(255, 150, 0, .3);
}

.CodeMirror-activeline-background {
  background: #e8f2ff;
}

/* STOP */

/* The rest of this file contains styles related to the mechanics of
     the editor. You probably shouldn't touch them. */

.CodeMirror {
  position: relative;
  overflow: hidden;
  background: white;
}

.CodeMirror-scroll, .CodeMirror-sizer, .CodeMirror-gutter, .CodeMirror-gutters, .CodeMirror-linenumber {
  box-sizing: content-box;
}

.CodeMirror-scroll {
  overflow: scroll !important;
  margin-bottom: -50px;
  margin-right: -50px;
  padding-bottom: 50px;
  height: 100%;
  outline: none;
  position: relative;
  z-index: 0;
}

.CodeMirror-sizer {
  position: relative;
  border-right: 30px solid transparent;
}

/* The fake, visible scrollbars. Used to force redraw during scrolling
     before actual scrolling happens, thus preventing shaking and
     flickering artifacts. */

.CodeMirror-vscrollbar, .CodeMirror-hscrollbar, .CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
  position: absolute;
  z-index: 6;
  display: none;
}

.CodeMirror-vscrollbar {
  right: 0; top: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.CodeMirror-hscrollbar {
  bottom: 0; left: 0;
  overflow-y: hidden;
  overflow-x: scroll;
}

.CodeMirror-scrollbar-filler {
  right: 0; bottom: 0;
}

.CodeMirror-gutter-filler {
  left: 0; bottom: 0;
}

.CodeMirror-gutters {
  position: absolute; left: 0; top: 0;
  min-height: 100%;
  z-index: 3;
}

.CodeMirror-gutter {
  white-space: normal;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: -30px;
}

.CodeMirror-gutter-wrapper {
  position: absolute;
  z-index: 4;
  background: none !important;
  border: none !important;
}

.CodeMirror-gutter-background {
  position: absolute;
  top: 0; bottom: 0;
  z-index: 4;
}

.CodeMirror-gutter-elt {
  position: absolute;
  cursor: default;
  z-index: 4;
}

.CodeMirror-gutter-wrapper ::-moz-selection { background-color: transparent
}

.CodeMirror-gutter-wrapper ::selection { background-color: transparent
}

.CodeMirror-gutter-wrapper ::-moz-selection { background-color: transparent
}

.CodeMirror-lines {
  cursor: text;
  min-height: 1px; /* prevents collapsing before first draw */
}

.CodeMirror pre.CodeMirror-line,
  .CodeMirror pre.CodeMirror-line-like {
  /* Reset some styles that the rest of the page might have set */ border-radius: 0;
  border-width: 0;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  white-space: pre;
  word-wrap: normal;
  line-height: inherit;
  color: inherit;
  z-index: 2;
  position: relative;
  overflow: visible;
  -webkit-tap-highlight-color: transparent;
  font-variant-ligatures: contextual;
}

.CodeMirror-wrap pre.CodeMirror-line,
  .CodeMirror-wrap pre.CodeMirror-line-like {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal;
}

.CodeMirror-linebackground {
  position: absolute;
  left: 0; right: 0; top: 0; bottom: 0;
  z-index: 0;
}

.CodeMirror-linewidget {
  position: relative;
  z-index: 2;
  padding: 0.1px; /* Force widget margins to stay inside of the container */
}

/* .CodeMirror-widget {} */

.CodeMirror-rtl pre { direction: rtl;
}

.CodeMirror-code {
  outline: none;
}

/* Force content-box sizing for the elements where we expect it */

.CodeMirror-scroll,
  .CodeMirror-sizer,
  .CodeMirror-gutter,
  .CodeMirror-gutters,
  .CodeMirror-linenumber {
  box-sizing: content-box;
}

.CodeMirror-measure {
  position: absolute;
  width: 100%;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.CodeMirror-cursor {
  position: absolute;
  pointer-events: none;
}

.CodeMirror-measure pre { position: static;
}

div.CodeMirror-cursors {
  visibility: hidden;
  position: relative;
  z-index: 3;
}

div.CodeMirror-dragcursors {
  visibility: visible;
}

.CodeMirror-focused div.CodeMirror-cursors {
  visibility: visible;
}

.CodeMirror-selected { background: #d9d9d9;
}

.CodeMirror-focused .CodeMirror-selected { background: #d7d4f0;
}

.CodeMirror-crosshair { cursor: crosshair;
}

.CodeMirror-line::-moz-selection, .CodeMirror-line > span::-moz-selection, .CodeMirror-line > span > span::-moz-selection { background: #d7d4f0;
}

.CodeMirror-line::selection, .CodeMirror-line > span::selection, .CodeMirror-line > span > span::selection { background: #d7d4f0;
}

.CodeMirror-line::-moz-selection, .CodeMirror-line > span::-moz-selection, .CodeMirror-line > span > span::-moz-selection { background: #d7d4f0;
}

.cm-searching {
  background-color: #ffa;
  background-color: rgba(255, 255, 0, .4);
}

/* Used to force a border model for a node */

.cm-force-border { padding-right: .1px;
}

@media print {
  /* Hide the cursor when printing */

  .CodeMirror div.CodeMirror-cursors {
    visibility: hidden;
  }
}

/* See issue #2901 */

.cm-tab-wrap-hack:after { content: '';
}

/* Help users use markselection to safely style text background */

span.CodeMirror-selectedtext { background: none;
}
/* Custom styling for tokens */

.cm-keyword {
  font-weight: bold;
  color: #0077aa;
}

.cm-number {
  color: #aa0077;
}

.cm-bracket {
  color: #000000;
}

.cm-operator {
  color: #d14;
  /* Example: Set operators to a red color */
}

.cm-string {
  color: #d14;
  /* Example: Set strings to a red color */
}

.cm-logic-operator {
  color: #008800;
  /* Green color for logical operators */
}

/* Custom styles for hint dropdown */

.CodeMirror-hints {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 250, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(217, 217, 217, var(--tw-border-opacity));
  border-radius: 0.25rem;
  border-style: solid;
  border-width: 1px;
  max-width: 600px;
  padding: 0px;
  width: 360px;
  z-index: 1002 !important;
}

.CodeMirror-hint {
  border-width: 0px;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
}

.CodeMirror-hint-active {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(230, 247, 255, var(--tw-bg-opacity)) !important;
}

.codemirror-container .editor-main-area,
.codemirror-container .editor-helper {
  border: 1px solid #cbd6e2;
}

.codemirror-container .editor-main-area .CodeMirror {
  padding: 12px 16px;
  background: #f5f8fa;
  border-radius: 0;
}

.codemirror-container .editor-helper {
  padding: 4px 8px;
}

.codemirror-container .editor-insert-popover {
  max-width: -moz-max-content;
  max-width: max-content;
}

.codemirror-container .editor-insert-popover .ant-popover-inner-content {
  padding: 4px 0;
}

.codemirror-container .formula-test-container {
  border: 1px solid #cbd6e2;
  margin-top: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 16px;
  padding-right: 16px;
}

.codemirror-container .formula-test-container .formula-test-header {
  display: flex;
  align-items: center;
}

.codemirror-container .formula-test-container .formula-test-header .formula-test-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
}

.codemirror-container .formula-test-container .formula-test-header .formula-test-actions {
  display: flex;
  align-items: center;
}

.codemirror-container .formula-test-container .formula-test-header .formula-test-actions .formula-test-action {
  padding-left: 8px;
  padding-right: 8px;
}

.codemirror-container .formula-test-container .formula-test-output {
  display: flex;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
}

@keyframes highlight {
  0% {
    border-color: #d9d9d9;
    box-shadow: none;
  }

  50% {
    border-color: #5792ff;
    box-shadow: 0 0 0 2px rgba(47, 112, 255, 0.2);
  }

  100% {
    border-color: #d9d9d9;
    box-shadow: none;
  }
}

.codemirror-container .formula-test-container .formula-test-output .formula-test-output-input.highlight {
  animation-name: highlight;
  animation-duration: 2s;
  border-color: #5792ff;
  box-shadow: 0 0 0 2px rgba(47, 112, 255, 0.2);
}

.codemirror-container .formula-test-container .formula-test-content {
  margin-top: 12px;
}

.codemirror-container .formula-test-container .formula-test-content .formula-test-table {
  border: 1px solid #cbd6e2;
}

.editor-insert-functions-popover, .editor-insert-merge-tags-popover {
  max-width: -moz-max-content;
  max-width: max-content;
}

.editor-insert-functions-popover .ant-popover-inner-content,
.editor-insert-merge-tags-popover .ant-popover-inner-content {
  padding: 0 !important;
}

.editor-helper .editor-insert-button {
  padding-left: 8px;
  padding-right: 8px;
}

.codemirror-container .generate-formula-button {
  background: linear-gradient(-45deg, #1c2b5f, #214392, #1c2b5f);
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

#autocomplete-dropdown,
#function-description {
  z-index: 1001;
}

#function-description {
  width: 348px;
}

#function-description .ant-collapse-header {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding: 8px !important;
  padding-bottom: 8px !important;
}

#function-description .ant-collapse-header .function-description-documentation-header {
  display: flex;
}

#function-description .ant-collapse-content {
  border-width: 0px;
}

#function-description .ant-collapse-content .ant-collapse-content-box {
  padding: 8px !important;
  padding-top: 0 !important;
}

#function-description .ant-collapse-content .ant-collapse-content-box .function-description-documentation-header {
  display: flex;
}

#function-description .ant-collapse-content .ant-collapse-content-box .function-description-documentation-content {
  --tw-bg-opacity: 1;
  background-color: rgba(214, 228, 255, var(--tw-bg-opacity));
  padding: 8px;
}

#function-description .ant-collapse-content .ant-collapse-content-box .function-description-documentation-content .function-description-documentation-content-section {
  margin-bottom: 8px;
}

.z-formula-editor-disclaimer {
  font-size: 0.64rem !important;
}

.z-try-with-ai-card-container {
  position: relative;
  width: 261px;
}

.z-try-with-ai-card-container .z-try-with-ai-card .ant-card-body {
  padding: 16px;
}

.z-try-with-ai-card-container .z-try-with-ai-card .ant-card-body .ant-card-meta .ant-card-meta-detail .ant-card-meta-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  margin-bottom: 0px;
  --tw-text-opacity: 1;
  color: rgba(38, 38, 38, var(--tw-text-opacity));
}

.z-try-with-ai-card-container .z-try-with-ai-card .ant-card-body .ant-card-meta .ant-card-meta-detail .ant-card-meta-description {
  font-size: 12px;
  line-height: 18px;
  --tw-text-opacity: 1;
  color: rgba(38, 38, 38, var(--tw-text-opacity));
}

.z-try-with-ai-card-container .z-try-with-ai-card-close {
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
}

.z-try-with-ai-button {
  border-radius: 0.25rem;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 2px 0px #2f90c11a, 0px 4px 4px 0px #2f90c117, 0px 9px 5px 0px #6a73770d, 0px 15px 6px 0px #2f90c103, 0px 24px 7px 0px #2f90c100;
  background: linear-gradient(86.96deg, #2f70ff 4.07%, #5cffd0 51.31%, #6ded02 83.46%);
  background-size: 200% 200%;
  padding: 1px;
}

.z-try-with-ai-button:hover {
  animation: gradient 15s ease infinite;
}

.z-try-with-ai-button > div {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 0.25rem;
  display: inline-flex;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 12px;
  padding-right: 12px;
}

.z-try-with-ai-input {
  border-radius: 0.25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(86.96deg, #2f70ff 4.07%, #5cffd0 51.31%, #6ded02 83.46%);
  background-size: 200% 200%;
  animation: gradient 15s ease infinite;
  padding: 1px;
}

.z-try-with-ai-input > div {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 0.25rem;
  display: inline-flex;
  align-items: center;
}

.z-try-with-ai-input .input {
  border-style: none;
  padding: 0px;
  padding-left: 12px;
  box-shadow: none;
}

.z-try-with-ai-input .generate-btn,
.z-try-with-ai-input .generate-btn:active,
.z-try-with-ai-input .generate-btn:focus {
  background: linear-gradient(99.48deg, #3071ff 9.44%, #5efec2 56.13%, #01b672 90.88%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.z-try-with-ai-input .generate-btn, .z-try-with-ai-input .generate-btn:active, .z-try-with-ai-input .generate-btn:focus {
  font-weight: 600;
}

@keyframes gradient {
  0% {
    background-position: 0% 0%;
  }

  17% {
    background-position: 50% 0%;
  }

  33% {
    background-position: 100% 0%;
  }

  50% {
    background-position: 100% 100%;
  }

  66% {
    background-position: 50% 100%;
  }

  83% {
    background-position: 0% 100%;
  }

  100% {
    background-position: 0% 0%;
  }
}
