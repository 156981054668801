@import "@/Config.less";
@import "@/stylesheets/theme.less";

.app-sider {
	&.ant-layout-sider {
		@apply overflow-auto fixed min-h-screen left-0 top-0 z-50;
		background-color: extract(@themeGray, 10);
		margin-top: 4.5rem;
		width: 14.3rem;

		&.update-banner-visible {
			margin-top: 6.8rem;
		}

		&.banner-open {
			margin-top: 6.5rem;
		}

		&.update-banner-visible.banner-open {
			margin-top: 8.8rem;
		}

		.ant-layout-sider-children {
			overflow: hidden;

			.ant-menu {
				background: transparent;

				.ant-menu-item {
					@apply opacity-60;

					&:after {
						border: none;
					}

					&:active {
						background-color: transparent;
					}

					.menu-item-container {
						.mask {
							// Mask is added to simulate an offset for tooltip component
							width: 43px; // Need to keep it hardcoded
						}
					}

					&.ant-menu-item-active {
						@apply opacity-100;
						&::after {
							@apply opacity-100 border-0 m-auto absolute left-0;
							background-color: transparent;
							border-left: 6px solid @primaryColor;
							transform: scaleY(1);
							right: unset;
						}
					}

					&.ant-menu-item-selected {
						@apply opacity-100 relative;
						background-color: transparent;

						&:after {
							@apply opacity-100 top-0 left-0 absolute border-0 m-auto;
							content: "";
							border-left: 6px solid @primaryColor;
							transform: scaleY(1);
						}
					}
				}

				.chat-menuitem {
					@apply absolute flex-col;
					@apply mx-1 !important;
					width: 4.1rem !important;
					bottom: 50px; //Don't use relative dimension as it is harcoded to stay in bottom

					&.update-banner-visible,
					&.banner-open {
						bottom: 83px;
					}

					&.update-banner-visible.banner-open {
						bottom: 105px;
					}

					.ant-divider {
						opacity: 0.6;
					}
					&.ant-menu-item-active {
						&::after {
							@apply border-0;
						}
					}

					&.ant-menu-item-selected {
						&:after {
							@apply border-0;
						}
					}
				}

				&.ant-menu-inline-collapsed {
					.ant-menu-item {
						@apply mx-2 my-5 flex justify-center p-0;
						width: 48px;
						height: 2.2rem;
						line-height: 2.2rem;
						.menu-item-container {
							@apply flex items-center;
						}
						&.show-beta-tag {
							height: 2.8rem;
							line-height: 2.8rem;
							.menu-item-container {
								@apply flex items-start;
								.z-beta-tag {
									@apply bottom-0 ~"-ml-0.5";
								}
							}
						}
					}
				}
			}
		}
	}
}

.settings-sider {
	height: calc(100vh - 4.5rem);

	&.update-banner-visible,
	&.banner-open {
		height: calc(100vh - 6.8rem);
	}

	&.update-banner-visible.banner-open {
		height: calc(100vh - 8.5rem);
	}

	&.ant-layout-sider {
		background-color: extract(@themeGray, 1);
		margin-left: 4.5rem;
		margin-top: 4.5rem;
		z-index: 100;

		.ant-layout-sider-children {
			@apply flex flex-col justify-between border-0 border-r border-solid;
			border-color: @border-color-base;

			.ant-menu {
				color: @text-color;
				@apply border-0;

				.ant-menu-item {
					@apply m-0 border-0 border-b border-r border-solid;

					height: 48px;
					line-height: 48px;
					border-color: @border-color-base;

					a {
						display: grid;
						grid-template-columns: 30px 1fr;
						align-items: center;
						grid-gap: 0.5rem;
					}

					&:not(.ant-menu-item-selected) {
						&:not(.ant-menu-item-active) {
							a {
								color: @text-color !important;
							}
						}
					}

					&.ant-menu-item-selected,
					&.ant-menu-item-active {
						background-color: @secondaryBgColor;
						@apply border-r;
						svg {
							stroke: @primaryColor;
						}
					}

					&.fixed-menu-tile {
						pointer-events: none;
						@apply border-0 border-b border-r border-solid;
						color: @secondaryTextColor !important;
						background-color: @tableHeaderColor !important;
						border-color: @border-color-base !important;

						&:hover {
							cursor: unset;
						}
					}

					&.logout-menu-title {
						@apply border-t;
					}
				}
			}
		}
	}
}

@themeRed: #fff1f0, #ffccc7, #ffa39e, #ff7875, #ff4d4f, #f5222d, #cf1322,
	#a8071a, #820014, #5c0011;@themeVolcano: #fff2e8, #ffd8bf, #ffbb96, #ff9c6e, #ff7a45, #fa541c, #d4380d,
	#ad2102, #871400, #610b00;@themeOrange: #fff7e6, #ffe7ba, #ffd591, #ffc069, #ffa940, #fa8c16, #d46b08,
	#ad4e00, #873800, #612500;@themeGold: #fffbe6, #fff1b8, #ffe58f, #ffd666, #ffc53d, #faad14, #d48806,
	#ad6800, #874d00, #613400;@themeYellow: #feffe6, #ffffb8, #fffb8f, #fff566, #ffec3d, #fadb14, #d4b106,
	#ad8b00, #876800, #614700;@themeLime: #fcffe6, #f4ffb8, #eaff8f, #d3f261, #bae637, #a0d911, #7cb305,
	#5b8c00, #3f6600, #254000;@themeGreen: #f6fef9, #f6ffed, #d9f7be, #b7eb8f, #95de64, #73d13d, #52c41a,
	#389e0d, #237804, #135200, #092b00;@themeCyan: #e6fffb, #b5f5ec, #87e8de, #5cdbd3, #36cfc9, #13c2c2, #08979c,
	#006d75, #00474f, #002329;@themeBlue: #e6f7ff, #bae7ff, #91d5ff, #69c0ff, #4b85ff, #2f70ff, #096dd9,
	#0050b3, #003a8c, #002766;@themeGeekBlue: #f6f9ff, #f0f5ff, #d6e4ff, #adc6ff, #85a5ff, #597ef7, #2f54eb,
	#1d39c4, #10239e, #061178, #030852;@themePurple: #fafaff, #f9f0ff, #efdbff, #d3adf7, #b37feb, #9254de, #722ed1,
	#531dab, #391085, #22075e, #120338;@themeMagenta: #fff0f6, #ffd6e7, #ffadd2, #ff85c0, #f759ab, #eb2f96, #c41d7f,
	#9e1068, #780650, #520339;@themeGray: #ffffff, #fafafa, #f5f5f5, #e8e8e8, #d9d9d9, #bfbfbf, #8c8c8c,
	#595959, #262626, #000000;@primaryColor: extract(@themeBlue, 6);@primaryHoverColor: extract(@themeBlue, 5);@primaryActiveColor: extract(@themeBlue, 7);@primaryBgHoverColor: extract(@themeGeekBlue, 1);@primaryBorderColor: extract(@themeBlue, 3);@titleColor: extract(@themeGray, 10);@primaryTextColor: extract(@themeGray, 9);@secondaryTextColor: extract(@themeGray, 8);@disableColor: extract(@themeGray, 6);@secondaryBorderColor: extract(@themeGray, 5);@dividersColor: extract(@themeGray, 4);@secondaryBgColor: extract(@themeGray, 3);@tableHeaderColor: extract(@themeGray, 2);@whiteColor: extract(@themeGray, 1);@primary-color: @primaryColor;@info-color: @primaryColor;@success-color: extract(@themeGreen, 6);@processing-color: @primaryColor;@error-color: extract(@themeRed, 6);@highlight-color: extract(@themeRed, 6);@warning-color: extract(@themeGold, 6);@normal-color: extract(@themeGray, 5);@white: extract(@themeGray, 1);@black: extract(@themeGray, 10);@link-color: extract(@themeBlue, 5);@font-size-base: 14px;@text-color: @primaryTextColor;@heading-color: extract(@themeGray, 10);@text-color-secondary: @secondaryTextColor;@disabled-color: @disableColor;@border-color-base: @secondaryBorderColor;@box-shadow-base: 0 6px 20px 0 rgba(0, 0, 0, 0.15);@border-radius-base: 4px;@item-active-bg: @primaryBgHoverColor;@item-hover-bg: @primaryBgHoverColor;@btn-shadow: none;@btn-primary-shadow: none;@btn-padding-horizontal-base: 18px;@divider-color: @dividersColor;@dropdown-selected-color: @primaryBgHoverColor;@label-color: @primaryTextColor;@select-item-selected-bg: @primaryBgHoverColor;@select-item-active-bg: @primaryBgHoverColor;@select-selection-item-bg: @primaryBgHoverColor;@tooltip-bg: @black;@tooltip-arrow-width: 10px;@popover-arrow-width: 10px;@popover-padding-horizontal: 24px;@rate-star-color: extract(@themeGold, 6);