.z-breadcrumb svg {
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 4px;
  stroke: currentColor;
}

.z-breadcrumb .ant-dropdown-link .dropdown-icon {
  margin-left: 4px;
  margin-bottom: 2px;
  cursor: auto;
}

.z-breadcrumb a {
  --tw-text-opacity: 1 !important;
  color: rgba(140, 140, 140, var(--tw-text-opacity)) !important;
}

.z-breadcrumb a:hover {
  --tw-text-opacity: 1 !important;
  color: rgba(75, 133, 255, var(--tw-text-opacity)) !important;
}

.z-breadcrumb .ant-breadcrumb-separator {
  --tw-text-opacity: 1;
  color: rgba(140, 140, 140, var(--tw-text-opacity));
}

.z-collapsible-card-content .overflow-hidden {
  overflow: hidden;
}

.z-collapsible-card-content .overflow-hidden.max-height-250 {
  max-height: 250px;
  height: unset !important;
}

.z-dropdown {
  display: inline-block;
  --tw-text-opacity: 1;
  color: rgba(47, 112, 255, var(--tw-text-opacity));
}

.z-dropdown svg {
  stroke: currentColor;
}

.z-dropdown.z-dropdown-disabled {
  cursor: not-allowed;
  --tw-text-opacity: 1;
  color: rgba(191, 191, 191, var(--tw-text-opacity));
}

.z-dropdown-button svg {
  stroke: currentColor;
}

.z-dropdown-overlay {
  max-width: 350px;
  min-width: -moz-min-content !important;
  min-width: min-content !important;
  box-shadow: none;
}

.z-menu.ant-dropdown-menu {
  max-width: 350px;
  min-width: 10.625rem;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15);
  max-height: 50rem;
  overflow-y: auto;
}

.z-menu.ant-dropdown-menu:not(.no-padding) {
  padding: 0.8rem 0;
}

.z-menu.ant-dropdown-menu .ant-dropdown-menu-item-divider {
  margin: 0px;
}

.z-menu.ant-dropdown-menu .ant-dropdown-menu-item-group {
  padding: 0px;
}

.z-menu.ant-dropdown-menu .ant-dropdown-menu-item-group .ant-dropdown-menu-item-group-title {
  padding-left: 20px;
}

.z-menu.ant-dropdown-menu .ant-dropdown-menu-item-group .ant-dropdown-menu-item-group-list {
  padding: 0.2rem 0rem;
  margin: 0px;
}

.z-menu.ant-dropdown-menu .ant-dropdown-menu-submenu-title {
  padding: 0.8rem 20px;
}

.z-menu.ant-dropdown-menu.disable-overflow {
  overflow: visible;
}

.z-menu-item {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.z-menu-item.z-menu-item-icon-right {
  flex-direction: row-reverse;
  justify-content: space-between;
}

.z-menu-item svg {
  stroke: currentColor;
}

.z-menu-item.ant-dropdown-menu-item {
  padding: 0.8rem 1.4rem;
  --tw-text-opacity: 1;
  color: rgba(38, 38, 38, var(--tw-text-opacity));
}

.z-menu-item.ant-dropdown-menu-item a {
  --tw-text-opacity: 1 !important;
  color: rgba(38, 38, 38, var(--tw-text-opacity)) !important;
}

.z-menu-item.ant-dropdown-menu-item:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(246, 249, 255, var(--tw-bg-opacity));
}

.z-menu-item.ant-dropdown-menu-item.ant-dropdown-menu-item-selected {
  --tw-bg-opacity: 1;
  background-color: rgba(246, 249, 255, var(--tw-bg-opacity));
}

.z-menu-item.ant-dropdown-menu-item-disabled {
  --tw-text-opacity: 1 !important;
  color: rgba(191, 191, 191, var(--tw-text-opacity)) !important;
}

.z-menu-item.ant-dropdown-menu-item-disabled a {
  cursor: not-allowed !important;
  --tw-text-opacity: 1 !important;
  color: rgba(191, 191, 191, var(--tw-text-opacity)) !important;
}

.z-menu-item.ant-dropdown-menu-item-disabled:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity)) !important;
}

.z-menu-item.ant-dropdown-menu-item-danger:not(.ant-dropdown-menu-item-disabled) {
  --tw-text-opacity: 1 !important;
  color: rgba(245, 34, 45, var(--tw-text-opacity)) !important;
}

.z-menu-item.ant-dropdown-menu-item-danger:not(.ant-dropdown-menu-item-disabled) a {
  --tw-text-opacity: 1 !important;
  color: rgba(245, 34, 45, var(--tw-text-opacity)) !important;
}

.z-input {
  width: 100%;
}

.z-input .input.input-width-sm {
  width: 6rem;
}

.z-input .input.input-width-md {
  width: 10rem;
}

.z-input .input.input-width-lg {
  width: 16rem;
}

.z-input .input.input-width-xl {
  width: 20rem;
}

.z-input .input.input-width-full-has-addon {
  flex-grow: 1;
  width: -moz-min-content;
  width: min-content;
}

.z-input .input.input-width-full {
  width: 100%;
}

.z-input .input .ant-input-handler-wrap {
  right: -22px;
  top: -1px;
  height: 32px;
  border: 1px solid #d9d9d9;
  opacity: 1;
}

.z-input .input.ant-input-disabled .ant-input-handler-wrap {
  display: block;
}

.z-input .input.ant-input-disabled .ant-input-handler-wrap .ant-input-handler {
  cursor: inherit;
}

.z-input .input.input-yes-addon .ant-input-handler-wrap {
  border-radius: 0px !important;
}

.z-input .input .ant-input-input-wrap .ant-input-input {
  text-align: right;
}

.z-input .z-input-length-count {
  margin-right: 4px;
}

.z-input .add-on-after {
  border-color: #d9d9d9;
  background-color: #f5f5f5;
  border-style: solid;
}

.z-input .add-on-before {
  border-color: #d9d9d9;
  background-color: #f5f5f5;
  border-style: solid;
}

.z-input .ant-input-group-addon {
  display: none;
}

.z-input .ant-input-search-with-button .ant-input-group-addon {
  display: table-cell;
}

.zMessage .ant-message-notice-content {
  padding: 0;
  background: none;
  box-shadow: none;
}

.zMessage .anticon-close-circle,
.zMessage .anticon-check-circle {
  display: none;
}

.index-module_container__QG8TF {
  min-height: 40px;
}

.index-module_zMessageWrap__fUYGC {
  min-height: 40px;
  border-width: 0.065rem;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.15);
}

.index-module_messageWrap__Ms89- {
  border: 0;
}

.index-module_errorType_200__UeEWc, .index-module_errorType_success__Apkzq {
  --tw-bg-opacity: 1;
  background-color: rgba(82, 196, 26, var(--tw-bg-opacity));
}

.index-module_successButton__MpQp4 {
  --tw-bg-opacity: 1;
  background-color: rgba(82, 196, 26, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(246, 254, 249, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(246, 254, 249, var(--tw-text-opacity));
}

.index-module_successButton__MpQp4:hover {
  --tw-border-opacity: 1;
  border-color: rgba(183, 235, 143, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(183, 235, 143, var(--tw-text-opacity));
}

.index-module_dangerButton__csh0f {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 77, 79, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(255, 241, 240, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 241, 240, var(--tw-text-opacity));
}

.index-module_dangerButton__csh0f:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 77, 79, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 77, 79, var(--tw-text-opacity));
}

.index-module_errorType_0__YKiOZ, .index-module_errorType_500__GrUHX, .index-module_errorType_400__4ZHJn, .index-module_errorType_error__xccu3 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 77, 79, var(--tw-bg-opacity));
}

.z-time-picker-popup .ant-picker-ranges {
  display: flex;
  justify-content: flex-end;
}

.z-drawer {
  z-index: 1000;
}

.z-drawer .ant-drawer-content-wrapper {
  width: 53% !important;
}

.z-drawer .ant-drawer-content-wrapper .form-container, .z-drawer .ant-drawer-content-wrapper .form-footer {
  padding-left: 24px;
  padding-right: 24px;
}

.z-drawer.mobile-view .ant-drawer-content-wrapper {
  width: 100% !important;
}

.z-date-picker-dropdown .ant-picker-panel-container {
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15);
}

.z-date-picker-dropdown .ant-picker-panel-container .ant-picker-cell-today .ant-picker-cell-inner {
  --tw-bg-opacity: 1;
  background-color: rgba(246, 249, 255, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(38, 38, 38, var(--tw-text-opacity));
}

.z-date-picker-dropdown .ant-picker-panel-container .ant-picker-cell-today .ant-picker-cell-inner::before {
  border-radius: 0px;
}

.z-date-picker-dropdown .ant-picker-panel-container .ant-picker-cell-inner {
  border-radius: 0px;
}

.index-module_zUploadModalWrapper__P-kj0 {
  width: 30rem;
  height: 20rem;
  overflow: hidden;
}

.index-module_videoStyles__Ksofh {
  max-height: 10rem;
}

.zUploadModalWrapper .uppy-DragDrop-arrow, .zUploadModalWrapper .uppy-ProgressBar {
  display: none;
}

.zUploadModalWrapper .uppy-DragDrop-container {
  width: 30rem !important;
  height: 20rem !important;
  background-color: inherit;
  border-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.zUploadModalWrapper .uppy-DragDrop-label {
  --tw-border-opacity: 1;
  border-color: rgba(9, 109, 217, var(--tw-border-opacity));
  border-radius: 0.375rem;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  margin-top: 56px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 12px;
  padding-right: 12px;
  --tw-text-opacity: 1;
  color: rgba(9, 109, 217, var(--tw-text-opacity));
}

.zUploadModalWrapper .ant-image-img {
  max-height: 10rem;
  width: 100%;
}

.zUploader .zUploadModalWrapper {
  width: 100% !important;
  height: 20rem;
}

.zUploader .zUploadModalWrapper .uppy-DragDrop-container[type="button"] {
  width: 100% !important;
  margin-top: 1rem;
}

.zUploader.remove-body-pad .modalBody {
  padding: 0px;
}

.integration-card-container {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  display: grid;
  grid-template-rows: 5.143rem 10.714rem auto;
}

.integration-card-container .logo-container {
  --tw-border-opacity: 1;
  border-color: rgba(191, 191, 191, var(--tw-border-opacity));
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  border-style: solid;
  border-width: 1px;
  border-bottom-width: 0px;
  display: flex;
  align-items: center;
}

.integration-card-container .logo-container img,
.integration-card-container .logo-container svg {
  -o-object-fit: contain;
     object-fit: contain;
  vertical-align: middle;
  height: 42px;
  width: 92px;
}

.integration-card-container .logo-container img, .integration-card-container .logo-container svg {
  margin: auto;
}

.integration-card-container .description {
  --tw-border-opacity: 1;
  border-color: rgba(191, 191, 191, var(--tw-border-opacity));
  border-style: solid;
  border-width: 1px;
  border-bottom-width: 0px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

.integration-card-container .description .edit-integration {
  transition: 150ms ease-in-out;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
}

.integration-card-container .description .edit-integration button {
  min-width: 10.8rem;
}

.integration-card-container .button-container .integration-cta-button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  width: 100%;
}

.integration-card-container.version-2 {
  --tw-border-opacity: 1;
  border-color: rgba(232, 232, 232, var(--tw-border-opacity));
  border-radius: 0.375rem;
  border-style: solid;
  border-width: 1px;
  padding: 16px;
  grid-template-rows: 3.143rem 10.714rem auto;
}

.integration-card-container.version-2 .logo-container {
  --tw-border-opacity: 1;
  border-color: rgba(232, 232, 232, var(--tw-border-opacity));
  border-radius: 0px;
  border-width: 0px;
  border-bottom-width: 1px;
}

.integration-card-container.version-2 .logo-container img, .integration-card-container.version-2 .logo-container svg {
  height: 36px;
  margin: auto;
  -o-object-fit: contain;
     object-fit: contain;
  vertical-align: middle;
}

.integration-card-container.version-2 .logo-container .logo-container-tag {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.integration-card-container.version-2 .logo-container .logo-container-tag img, .integration-card-container.version-2 .logo-container .logo-container-tag svg {
  margin: 0px;
}

.integration-card-container.version-2 .logo-container .category-tag {
  --tw-bg-opacity: 1;
  background-color: rgba(230, 247, 255, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(145, 213, 255, var(--tw-border-opacity));
  border-radius: 0.375rem;
  border-style: solid;
  border-width: 1px;
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgba(47, 112, 255, var(--tw-text-opacity));
}

.integration-card-container.version-2 .description {
  --tw-border-opacity: 1;
  border-color: rgba(232, 232, 232, var(--tw-border-opacity));
  border-width: 0px;
  border-bottom-width: 1px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.integration-card-container.version-2 .button-container .integration-cta-button {
  border-radius: 0.25rem;
}

.integration-card-container.version-2 .button-container .integration-cta-button.cta-v2-button {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-width: 0px;
  --tw-text-opacity: 1;
  color: rgba(47, 112, 255, var(--tw-text-opacity));
}

.action-btn.connected {
  --tw-bg-opacity: 1;
  background-color: rgba(115, 209, 61, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(115, 209, 61, var(--tw-border-opacity));
}

.action-btn.intermediate {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 140, 22, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(250, 140, 22, var(--tw-border-opacity));
}

.action-btn:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 34, 45, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(245, 34, 45, var(--tw-border-opacity));
}

.action-btn:hover .disconnect {
  display: inline !important;
}

.action-btn:hover .connected-intermediate {
  display: none !important;
}

.pricing-plan-error-alert {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  padding-left: 32px !important;
  padding-right: 32px !important;
  text-align: center !important;
  max-width: 36rem;
}

.pricing-plan-error-alert .icon {
  width: 36px;
  height: 36px;
  margin-bottom: 20px;
}

.pricing-plan-error-alert .content {
  background: transparent;
}

.pricing-plan-error-tooltip {
  max-width: 26rem;
}

.pricing-plan-error-tooltip .ant-tooltip-inner {
  width: 26rem;
  padding: 0px;
}

.z-resource-grid-file {
  --tw-border-opacity: 1;
  border-color: rgba(232, 232, 232, var(--tw-border-opacity));
  border-radius: 0.5rem;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  margin-bottom: 24px;
  --tw-text-opacity: 1;
  color: rgba(89, 89, 89, var(--tw-text-opacity));
  width: 100%;
}

.z-resource-grid-file .file-thumbnail {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 172px;
}

.z-resource-grid-file .file-thumbnail .ant-image {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.z-resource-grid-file .z-resource-grid-file-description-wrap {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 193px;
}

.recently-added {
  --duration: 3000ms;
  --delay: 500ms;
  animation: fade var(--duration) ease-in-out;
  animation-delay: var(--delay);
}

@keyframes fade {
  from {
    --tw-bg-opacity: 1;
    background-color: rgba(246, 249, 255, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(89, 126, 247, var(--tw-text-opacity));
  }

  to {
    background-color: transparent;
    --tw-text-opacity: 1;
    color: rgba(38, 38, 38, var(--tw-text-opacity));
  }
}

.recently-added .z-resource-grid-file-description {
  animation: fade var(--duration) ease-in-out;
  animation-delay: var(--delay);
}

.additional-fee-popover {
  width: 312px !important;
}

.pop-input {
  padding: 0.25rem 0.5rem;
  border-radius: 6px;
  cursor: pointer;
  background-color: #e8e8e8;
  transition: all 0.3s ease-in-out;
  overflow-wrap: break-word;
  border: 1px solid #d8d8d8;
  font-size: 0.7rem;
  font-family: "Inter", sans-serif, Helvetica, Arial !important;
  width: -moz-min-content;
  width: min-content;
}

.pop-input:focus,
.pop-input:hover {
  background-color: #d8d8d8;
}

.pop-input.right-aligned {
  text-align: right;
}

.pop-input.ant-popover-open {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  background-color: #e9ecf3;
}

.pop-input.disable-pop-input {
  pointer-events: none;
}

.popover-label-input {
  display: flex;
  align-items: center;
}

.popover-label-input .ant-popover-inner-content {
  padding-right: 0;
  overflow: hidden;
}

.popover-label-input button {
  transform: scale(1.2);
  position: relative;
  right: -0.5rem;
  padding: 0;
}

.popover-label-input input.ant-input {
  background-color: #f7f7f7;
}

.hover_pointer {
  cursor: pointer;
  display: inline-flex !important;
}

.inlineEditContainer {
  background-color: #fafafa;
  border: 1px solid #f5f5f5;
  color: #595959;
  word-break: break-all;
  border-radius: 0.25rem;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;
}

.inlineEditContainer.disabled {
  pointer-events: none;
  cursor: not-allowed;
  --tw-text-opacity: 1;
  color: rgba(89, 89, 89, var(--tw-text-opacity));
}

.inlineEditContainer.inline-edit-skeleton {
  border-style: none;
  padding: 0px;
}

.inputPlaceholder {
  border-radius: 0.25rem;
}

.inlineInput {
  background-color: #fafafa;
  border: 1px solid #f5f5f5;
  color: #595959;
  position: relative;
  z-index: 1;
  top: 0;
  bottom: 0px;
  right: 0;
}

.inlineInput:focus {
  --tw-border-opacity: 1;
  border-color: rgba(75, 133, 255, var(--tw-border-opacity));
}

.inlineInput {
  border-radius: 0.25rem;
  outline: 2px solid transparent;
  outline-offset: 2px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;
}

.floatingIcons {
  position: absolute;
  right: 0;
  background-color: #ffffff;
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  justify-content: flex-end;
  bottom: -2.8rem;
  box-shadow: 0px 1px 6px 1px #00000036;
  z-index: 2;
  border-radius: 0.25rem;
  padding: 4px;
}

.floatingIcons svg {
  cursor: pointer;
  stroke: none !important;
}

.floatingIcons svg:first-child {
  margin-right: 0.4rem;
}

.minWidth-xs {
  min-width: calc(8ch - 18px);
}

.minWidth-sm {
  min-width: calc(14ch - 18px);
}

.minWidth-md {
  min-width: calc(24ch - 18px);
}

.minWidth-lg {
  min-width: calc(32ch - 18px);
}

.minWidth-xl {
  min-width: calc(40ch - 18px);
}

.inlineEditWidth-xs {
  width: 8ch;
}

.inlineEditWidth-sm {
  width: 14ch;
}

.inlineEditWidth-md {
  width: 24ch;
}

.inlineEditWidth-lg {
  width: 32ch;
}

.inlineEditWidth-xl {
  width: 40ch;
}

.inline-edit-skeleton .ant-skeleton-title {
  height: 30px;
  margin: 0px;
}

.product-discount-dropdown ul {
  overflow-y: unset !important;
}

form .merge_tag-dropdown-container .anticon-search {
  margin-right: 0;
}

.ant-cascader-menus.merge-tags-cascader.with-bottom-action .ant-cascader-menu:first-child {
  padding-bottom: 44px;
}

.ant-cascader-menus.merge-tags-cascader.with-bottom-action .ant-cascader-menu:first-child .ant-cascader-menu-item:last-child {
  padding-top: 0px;
  padding-bottom: 0px;
}

.z-pending-requests-banner {
  --tw-bg-opacity: 1;
  background-color: rgba(246, 249, 255, var(--tw-bg-opacity));
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  overflow: hidden;
  position: relative;
}

.z-pending-requests-banner .circle-wrap {
  border-radius: 9999px;
  display: block;
  height: 228px;
  position: absolute;
  width: 228px;
  background: linear-gradient(179.67deg, rgba(255, 255, 255, 0) -15.9%, #b0e3ff 158.68%);
}

.z-pending-requests-banner .circle-wrap-1 {
  top: 30px;
  left: -40px;
  transform: matrix(-0.39, 0.92, -0.92, -0.39, 0, 0);
}

.z-pending-requests-banner .circle-wrap-2 {
  top: -26px;
  left: 70px;
  transform: matrix(0.26, 0.97, -0.97, 0.26, 0, 0);
  background-color: rgba(240, 245, 255, 0.5);
}

.z-pending-requests-banner .circle-wrap-3 {
  transform: matrix(-0.26, -0.97, 0.97, -0.26, 0, 0);
  top: -147px;
  right: 70px;
  background-color: rgba(240, 245, 255, 0.5);
}

.z-pending-requests-banner .circle-wrap-4 {
  top: -203px;
  right: -44px;
  transform: matrix(0.39, -0.92, 0.92, 0.39, 0, 0);
}

.z-pipeline-progress {
  margin-bottom: 12px;
}

.z-pipeline-progress .ant-progress-outer {
  padding: 0px !important;
}

.z-pipeline-progress .ant-progress-outer .ant-progress-inner {
  border-radius: 0.125rem;
}

.z-pipeline-progress .ant-progress-outer .ant-progress-inner .ant-progress-bg {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(115, 209, 61, var(--tw-bg-opacity)) !important;
  border-radius: 0.125rem !important;
  height: 24px !important;
}

.z-pipeline-progress .ant-progress-text {
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: -0.01em !important;
  margin-left: -8px !important;
  position: absolute !important;
  top: 50% !important;
  --tw-translate-x: 0 !important;
  --tw-translate-y: 0 !important;
  --tw-rotate: 0 !important;
  --tw-skew-x: 0 !important;
  --tw-skew-y: 0 !important;
  --tw-scale-x: 1 !important;
  --tw-scale-y: 1 !important;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  --tw-translate-y: -50% !important;
}

.z-pipeline-progress-popover {
  width: -moz-max-content !important;
  width: max-content !important;
}

.z-pipeline-progress-popover .ant-popover-inner-content {
  padding: 24px !important;
}

.z-pipeline-progress-popover .ant-popover-inner-content .z-pipeline-progress {
  margin-bottom: 0px;
}
